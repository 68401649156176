export { parseDateTime } from './parseDateTime';
export { parseEmbed } from './parseEmbed';
export { parseImage } from './parseImage';
export { parseLink, parseLinkFromMeta } from './parseLink';
export { parseRichText } from './parseRichText';
export { parseString } from './parseString';

import { parseDateTime } from './parseDateTime';
import { parseEmbed } from './parseEmbed';
import { parseImage } from './parseImage';
import { parseLink, parseLinkFromMeta } from './parseLink';
import { parseRichText } from './parseRichText';
import { parseString } from './parseString';

export const parser = {
  dateTime: parseDateTime,
  embed: parseEmbed,
  image: parseImage,
  link: parseLink,
  linkFromMeta: parseLinkFromMeta,
  richText: parseRichText,
  string: parseString,
};
