import gql from 'graphql-tag';
import { print } from 'graphql';
import { RichTextBlock } from 'prismic-reactjs';

import { fetchAPI, IPrismicObject, linkFragment } from '.';
import { IImage, ILink } from 'utils/types';
import { PrismicImage, PrismicLink } from './utils/PrismicTypes';
import {
  parseDateTime,
  parseImage,
  parseLink,
  parseLinkFromMeta,
  parseRichText,
  parseString,
} from './utils/parsers';

export interface IPrismicJob extends IPrismicObject {
  title: string;
  tagline: string;
  date: string;
  location: string;
  summary: RichTextBlock[];
  image: IImage;
  text: RichTextBlock[];
  link: ILink;
  detailsLink?: ILink;
}

export type PrismicJobs = {
  allJobs: {
    pageInfo?: {
      hasNextPage: boolean;
      endCursor: string;
    };
    edges: Array<{
      node: {
        tagline: RichTextBlock[];
        title: RichTextBlock[];
        date: string;
        location: string;
        summary: RichTextBlock[];
        image: PrismicImage;
        text: RichTextBlock[];
        link: PrismicLink;
        _meta: {
          uid: string;
          type: string;
          lang: string;
        };
      };
    }>;
  };
};

export const fetchJobs = async (
  lang: string,
  endCursor: string = '',
  previewData: boolean,
  limit?: number
): Promise<PrismicJobs> => {
  return await fetchAPI(
    print(gql`
      query LatestJobs($lang: String!, $endCursor: String, $limit: Int) {
        allJobs(lang: $lang, sortBy: date_DESC, first: $limit, after: $endCursor) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              tagline
              title
              date
              location
              summary
              image
              text
              link {
                ...Link
              }
              _meta {
                uid
                type
                lang
              }
            }
          }
        }
      }
      ${linkFragment}
    `),
    {
      previewData,
      variables: {
        lang,
        endCursor,
        limit,
      },
    }
  );
};

// Recursivly fetch all items in a list since prismic graphql endpoint only returns max 20 items
const fetchAllJobs = async (
  lang: string,
  endCursor: string = '',
  previewData,
  limit?: number
): Promise<PrismicJobs> => {
  const jobs = await fetchJobs(lang, endCursor, previewData, limit);

  if (jobs.allJobs.pageInfo.hasNextPage && jobs.allJobs.edges.length < limit) {
    const nestedList = await fetchAllJobs(
      lang,
      jobs.allJobs.pageInfo.endCursor,
      previewData,
      limit
    );
    return {
      allJobs: {
        edges: [...jobs.allJobs.edges, ...nestedList.allJobs.edges],
      },
    };
  }
  return jobs;
};

export const getAllArticles = async ({
  previewData = false,
  lang = undefined,
  limit = 3,
  redirects = [],
} = {}): Promise<IPrismicJob[]> => {
  const items = await fetchAllJobs(lang, '', previewData, limit);

  return parseJobs(items, redirects, lang);
};

export async function getJobBySlug(
  slug: string,
  { previewData = false, lang = undefined, redirects = [] }
): Promise<IPrismicJob> {
  const query = gql`
    query GetJob($slug: String!, $lang: String!) {
      job(uid: $slug, lang: $lang) {
        title
        tagline
        date
        location
        summary
        image
        text
        link {
          ...Link
        }
      }
    }
    ${linkFragment}
  `;

  const res = await fetchAPI(print(query), {
    previewData,
    variables: {
      slug,
      lang,
    },
  });

  if (!res || !res.job) {
    return null;
  }

  const data = res.job;

  return {
    title: parseString(data.title),
    tagline: parseString(data.tagline),
    date: parseDateTime(data.date, undefined, lang),
    location: parseString(data.location),
    summary: parseRichText(data.summary, redirects),
    image: parseImage(data.image),
    text: parseRichText(data.text, redirects),
    link: parseLink(data.link),
  };
}

export const parseJobs = (items: PrismicJobs, redirects, lang): IPrismicJob[] => {
  return (
    items?.allJobs?.edges?.map(({ node: item }) => ({
      tagline: parseString(item.tagline),
      title: parseString(item.title),
      date: parseDateTime(item.date, undefined, lang),
      location: parseString(item.location),
      summary: parseRichText(item.summary, redirects),
      image: parseImage(item.image),
      text: parseRichText(item.text, redirects),
      link: parseLink(item.link),
      detailsLink: parseLinkFromMeta(item._meta, redirects),
    })) ?? null
  );
};
