import gql from 'graphql-tag';
import { print } from 'graphql';
import { RichTextBlock } from 'prismic-reactjs';

import { fetchAPI, IPrismicObject, linkFragment } from '.';
import { ILink } from 'utils/types';
import { PrismicLink } from './utils/PrismicTypes';
import { parseLink, parseString, parseRichText, parseLinkFromMeta } from './utils/parsers';

export interface IPrismicSimpleProduct extends IPrismicObject {
  category: string;
  title: string;
  ingredients: string;
  form: string;
  strength: string;
  therapeuticArea: string;
  timeline: string;
  scope: string;
  tags: string[];
  downloads: Array<{
    label: string;
    link: ILink;
  }>;
}

export type PrismicSimpleProducts = {
  allSimple_products: {
    pageInfo?: {
      hasNextPage: boolean;
      endCursor: string;
    };
    edges: Array<{
      node: {
        category: RichTextBlock[];
        title: RichTextBlock[];
        ingredients: RichTextBlock[];
        form: RichTextBlock[];
        strength: RichTextBlock[];
        therapeutic_area: RichTextBlock[];
        timeline: RichTextBlock[];
        scope: RichTextBlock[];
        downloads: Array<{
          item_label: RichTextBlock[];
          item_link: PrismicLink;
        }>;
        _meta: {
          uid: string;
          type: string;
          lang: string;
          tags: string[];
        };
      };
    }>;
  };
};

export const fetchSimpleProducts = async (
  lang: string,
  endCursor: string = '',
  previewData: boolean,
  limit?: number
): Promise<PrismicSimpleProducts> => {
  return await fetchAPI(
    print(gql`
      query LatestProducts($lang: String!, $endCursor: String, $limit: Int) {
        allSimple_products(lang: $lang, sortBy: title_ASC, first: $limit, after: $endCursor) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              category
              title
              ingredients
              form
              strength

              therapeutic_area
              timeline
              scope

              downloads {
                item_label
                item_link {
                  ...Link
                }
              }
              _meta {
                uid
                type
                lang
                tags
              }
            }
          }
        }
      }
      ${linkFragment}
    `),
    {
      previewData,
      variables: {
        lang,
        endCursor,
        limit,
      },
    }
  );
};

// Recursivly fetch all items in a list since prismic graphql endpoint only returns max 20 items
export const fetchAllSimpleProducts = async (
  lang: string,
  endCursor: string = '',
  previewData,
  limit?: number
): Promise<PrismicSimpleProducts> => {
  const products = await fetchSimpleProducts(lang, endCursor, previewData, limit);

  if (
    products.allSimple_products.pageInfo.hasNextPage &&
    products.allSimple_products.edges.length < limit
  ) {
    const nestedList = await fetchAllSimpleProducts(
      lang,
      products.allSimple_products.pageInfo.endCursor,
      previewData,
      limit
    );
    return {
      allSimple_products: {
        edges: [...products.allSimple_products.edges, ...nestedList.allSimple_products.edges],
      },
    };
  }
  return products;
};

export const getAllSimpleProducts = async ({
  previewData = false,
  lang = undefined,
  limit = 3,
  redirects = [],
} = {}): Promise<IPrismicSimpleProduct[]> => {
  const items = await fetchAllSimpleProducts(lang, '', previewData, limit);
  return parseSimpleProducts(items, redirects);
};

export const parseSimpleProducts = (
  items: PrismicSimpleProducts,
  redirects
): IPrismicSimpleProduct[] => {
  return (
    items?.allSimple_products?.edges.map(({ node: item }) => ({
      category: parseString(item.category),
      title: parseString(item.title),
      ingredients: parseString(item.ingredients),
      form: parseString(item.form),
      strength: parseString(item.strength),
      therapeuticArea: parseString(item.therapeutic_area),
      timeline: parseString(item.timeline),
      scope: parseString(item.scope),
      tags: item._meta.tags,
      downloads: item.downloads.map((download) => ({
        label: parseString(download.item_label),
        link: parseLink(download.item_link, redirects),
      })),
    })) ?? null
  );
};
