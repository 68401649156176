import gql from 'graphql-tag';
import { print } from 'graphql';
import { RichTextBlock } from 'prismic-reactjs';

import { fetchAPI, IPrismicObject, linkFragment } from '.';
import { IImage, ILink } from 'utils/types';
import { PrismicImage, PrismicLink } from './utils/PrismicTypes';
import { generateSliceFragments, IBaseSlice, PageTypes, parseSlices } from './slices';
import {
  parseImage,
  parseLink,
  parseString,
  parseRichText,
  parseLinkFromMeta,
} from './utils/parsers';

export interface IPrismicBasicProduct extends IPrismicObject {
  uid: string;
  tagline: string;
  title: string;
  description: RichTextBlock[];
  intro?: string;

  drugType: string;
  activeIngredient: string;
  form: string;
  strengths: string;
  sizes: string;

  warning: RichTextBlock[];
  adNumber: string;

  images: Array<{
    image: IImage;
  }>;
  links: Array<{
    label: string;
    link: ILink;
  }>;

  hero?: {
    title: string;
    tagline: string;
    image: IImage;
    textColor: boolean;
    textRight: boolean;
    video: ILink;
    background: string;
  };

  tags: string[];
  slices?: IBaseSlice[];
  detailsLink?: ILink;
}

export type PrismicBasicProducts = {
  allBasic_products: {
    pageInfo?: {
      hasNextPage: boolean;
      endCursor: string;
    };
    edges: Array<{
      node: {
        tagline: RichTextBlock[];
        title: RichTextBlock[];
        description: RichTextBlock[];
        intro: RichTextBlock[];
        drug_type: RichTextBlock[];
        active_ingredient: RichTextBlock[];
        form: RichTextBlock[];
        strengths: RichTextBlock[];
        sizes: RichTextBlock[];
        warning: RichTextBlock[];
        advertising_number: RichTextBlock[];
        images: Array<{
          item_image: PrismicImage;
        }>;
        links: Array<{
          item_label: RichTextBlock[];
          item_link: PrismicLink;
        }>;
        _meta: {
          uid: string;
          type: string;
          lang: string;
          tags: string[];
        };
      };
    }>;
  };
};

export const fetchBasicProducts = async (
  lang: string,
  endCursor: string = '',
  previewData: boolean,
  limit?: number,
  tags?: string[]
): Promise<PrismicBasicProducts> => {
  return await fetchAPI(
    print(gql`
      query LatestProducts($lang: String!, $endCursor: String, $limit: Int, $tags: [String!]) {
        allBasic_products(
          lang: $lang
          sortBy: title_DESC
          first: $limit
          after: $endCursor
          tags_in: $tags
        ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              tagline
              title
              description
              intro

              drug_type
              active_ingredient
              form
              strengths
              sizes

              warning
              advertising_number

              images {
                item_image
              }
              links {
                item_label
                item_link {
                  ...Link
                }
              }

              _meta {
                uid
                type
                lang
                tags
              }
            }
          }
        }
      }
      ${linkFragment}
    `),
    {
      previewData,
      variables: {
        lang,
        endCursor,
        limit,
        tags,
      },
    }
  );
};

// Recursivly fetch all items in a list since prismic graphql endpoint only returns max 20 items
export const fetchAllBasicProducts = async (
  lang: string,
  endCursor: string = '',
  previewData,
  limit?: number,
  tags?: string[]
): Promise<PrismicBasicProducts> => {
  const products = await fetchBasicProducts(lang, endCursor, previewData, limit, tags);

  if (
    products.allBasic_products.pageInfo.hasNextPage &&
    products.allBasic_products.edges.length < limit
  ) {
    const nestedList = await fetchAllBasicProducts(
      lang,
      products.allBasic_products.pageInfo.endCursor,
      previewData,
      limit
    );
    return {
      allBasic_products: {
        edges: [...products.allBasic_products.edges, ...nestedList.allBasic_products.edges],
      },
    };
  }
  return products;
};

export const getAllBasicProducts = async ({
  previewData = false,
  lang = undefined,
  limit = 3,
  redirects = [],
} = {}): Promise<IPrismicBasicProduct[]> => {
  const items = await fetchAllBasicProducts(lang, '', previewData, limit);

  return parseBasicProducts(items, redirects);
};

export async function getBasicProductBySlug(
  slug: string,
  { previewData = false, lang = undefined, redirects = [] }
): Promise<IPrismicBasicProduct> {
  const basicProductSliceFragments = await generateSliceFragments(
    PageTypes.BasicProduct,
    'BasicProductSlices'
  );

  const query = gql`
    query GetBasicProduct($slug: String!, $lang: String!) {
      basic_product(uid: $slug, lang: $lang) {
        tagline
        title
        description
        intro

        drug_type
        active_ingredient
        form
        strengths
        sizes

        warning
        advertising_number

        images {
          item_image
        }
        links {
          item_label
          item_link {
            ...Link
          }
        }

        hero_title
        hero_tagline
        hero_image
        hero_color
        hero_side
        hero_video {
          ...Link
        }
        hero_background

        body {
          ...BasicProductSlices
        }
        _meta {
          uid
          type
          lang
          tags
        }
      }
    }
    ${linkFragment}
    ${basicProductSliceFragments}
  `;

  const res = await fetchAPI(print(query), {
    previewData,
    variables: {
      slug,
      lang,
    },
  });

  if (!res || !res.basic_product) {
    return null;
  }

  const data = res.basic_product;

  const slices = await parseSlices(data.body || [], lang, previewData, redirects);

  return {
    uid: data._meta.uid || null,
    tagline: parseString(data.tagline),
    title: parseString(data.title),
    description: parseRichText(data.description, redirects),

    drugType: parseString(data.drug_type),
    activeIngredient: parseString(data.active_ingredient),
    form: parseString(data.form),
    strengths: parseString(data.strengths),
    sizes: parseString(data.sizes),

    warning: parseRichText(data.warning, redirects),
    adNumber: parseString(data.advertising_number),

    images: data.images.map((image) => ({
      image: parseImage(image.item_image),
    })),
    links: data.links.map((link) => ({
      label: parseString(link.item_label),
      link: parseLink(link.item_link),
    })),

    hero: {
      title: parseString(data.hero_title),
      tagline: parseString(data.hero_tagline),
      image: parseImage(data.hero_image),
      textColor: data.hero_color,
      textRight: data.hero_side,
      video: parseLink(data.hero_video),
      background: data.hero_background || null,
    },

    tags: data._meta.tags,
    slices,
  };
}

export const parseBasicProducts = (
  items: PrismicBasicProducts,
  redirects
): IPrismicBasicProduct[] => {
  return (
    items?.allBasic_products?.edges.map(({ node: item }) => ({
      uid: item._meta.uid || null,
      tagline: parseString(item.tagline),
      title: parseString(item.title),
      description: parseRichText(item.description, redirects),
      intro: parseString(item.intro),

      drugType: parseString(item.drug_type),
      activeIngredient: parseString(item.active_ingredient),
      form: parseString(item.form),
      strengths: parseString(item.strengths),
      sizes: parseString(item.sizes),

      warning: parseRichText(item.warning, redirects),
      adNumber: parseString(item.advertising_number),

      tags: item._meta.tags,
      images: item.images.map((image) => ({
        image: parseImage(image.item_image),
      })),
      links: item.links.map((link) => ({
        label: parseString(link.item_label),
        link: parseLink(link.item_link),
      })),

      detailsLink: parseLinkFromMeta(item._meta, redirects),
    })) ?? null
  );
};
