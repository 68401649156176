import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IBaseSlice, SliceTypes } from '..';
import { parser } from '../../utils/parsers';

export interface ITable extends IBaseSlice {
  title: string;
  text: RichTextBlock[];
  items: Array<{
    column1: string;
    column2: string;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_title
        slice_text
      }
      fields {
        item_column1
        item_column2
      }
    }
  }
`;

type Props = {
  primary: {
    slice_title: RichTextBlock[];
    slice_text: RichTextBlock[];
  };
  fields: Array<{
    item_column1: RichTextBlock[];
    item_column2: RichTextBlock[];
  }>;
};

export const parse = (slice: Props, redirects): ITable => ({
  type: SliceTypes.Table,
  title: parser.string(slice.primary.slice_title),
  text: parser.richText(slice.primary.slice_text, redirects),
  items: slice.fields.map((field) => ({
    column1: parser.string(field.item_column1),
    column2: parser.string(field.item_column2),
  })),
});
