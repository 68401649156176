import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IBaseSlice, SliceTypes } from '..';
import { parser } from '../../utils/parsers';

export interface IItemList extends IBaseSlice {
  title: string;
  text: RichTextBlock[];
  numbered: boolean;
  items: Array<{
    title: string;
    text: RichTextBlock[];
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_title
        slice_text
        slice_numbered
      }
      fields {
        item_title
        item_text
      }
    }
  }
`;

type Props = {
  primary: {
    slice_title: RichTextBlock[];
    slice_text: RichTextBlock[];
    slice_numbered: boolean;
  };
  fields: Array<{
    item_title: RichTextBlock[];
    item_text: RichTextBlock[];
  }>;
};

export const parse = (slice: Props, redirects): IItemList => ({
  type: SliceTypes.ItemList,
  title: parser.string(slice.primary.slice_title),
  text: parser.richText(slice.primary.slice_text, redirects),
  numbered: slice.primary.slice_numbered || false,
  items: slice.fields?.map((field) => ({
    title: parser.string(field.item_title),
    text: parser.richText(field.item_text, redirects),
  })),
});
