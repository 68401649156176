import gql from 'graphql-tag';
import { RichTextBlock } from 'prismic-reactjs';
import { IImage, ILink } from 'utils/types';
import { IBaseSlice, SliceTypes } from '..';
import { fetchAlmajectProducts, parseAlmajectProducts } from '../../almajectProduct';
import { PrismicLink } from '../../utils/PrismicTypes';
import { parser } from '../../utils/parsers';

export interface ICatalogAlmaject extends IBaseSlice {
  tagline: string;
  title: string;
  text: RichTextBlock[];
  footnote: RichTextBlock[];
  link: ILink;
  items: Array<{
    uid?: string;
    title?: RichTextBlock[];
    referenceDrug?: RichTextBlock[];
    images?: Array<{
      image: IImage;
    }>;
    boxedWarning?: boolean;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_tagline
        slice_title
        slice_text
        slice_footnote
        slice_link { ...Link }
      }
    }
  }
`;

type NestedProps = {
  lang: string;
  limit?: number;
  previewData: boolean;
  redirects: Array<{ from: string; to: string }>;
};

export const fetchProductsAndParse = async ({
  lang,
  limit,
  previewData,
  redirects = [],
}: NestedProps) => {
  const items = await fetchAlmajectProducts(lang, '', previewData, (limit = 500));
  return parseAlmajectProducts(items, redirects);
};

type Props = {
  primary: {
    slice_tagline: RichTextBlock[];
    slice_title: RichTextBlock[];
    slice_text: RichTextBlock[];
    slice_footnote: RichTextBlock[];
    slice_link: PrismicLink;
  };
};

export const parse = (slice: Props, redirects): ICatalogAlmaject => ({
  type: SliceTypes.CatalogAlmaject,
  tagline: parser.string(slice.primary.slice_tagline),
  title: parser.string(slice.primary.slice_title),
  text: parser.richText(slice.primary.slice_text, redirects),
  footnote: parser.richText(slice.primary.slice_footnote, redirects),
  link: parser.link(slice.primary.slice_link, redirects),
  items: [],
});
