import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IEmbed, IImage, ILink } from 'utils/types';
import { IBaseSlice, SliceTypes } from '..';
import { PrismicEmbed, PrismicImage, PrismicLink } from '../../utils/PrismicTypes';
import { parser } from '../../utils/parsers';

export interface IHero extends IBaseSlice {
  tagline: string;
  title: string;
  text: RichTextBlock[];
  linkText: string;
  link: ILink;
  image: IImage;
  video: ILink;
  embed: IEmbed;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_tagline
        slice_title
        slice_text
        slice_link_text
        slice_link { ...Link }
        slice_image
        slice_video { ...Link }
        slice_embed
      }
    }
  }
`;

type Props = {
  primary: {
    slice_tagline: RichTextBlock[];
    slice_title: RichTextBlock[];
    slice_text: RichTextBlock[];
    slice_link_text: RichTextBlock[];
    slice_link: PrismicLink;
    slice_image: PrismicImage;
    slice_video: PrismicLink;
    slice_embed: PrismicEmbed;
  };
};

export const parse = (slice: Props, redirects): IHero => ({
  type: SliceTypes.Hero,
  tagline: parser.string(slice.primary.slice_tagline),
  title: parser.string(slice.primary.slice_title),
  text: parser.richText(slice.primary.slice_text, redirects),
  linkText: parser.string(slice.primary.slice_link_text),
  link: parser.link(slice.primary.slice_link, redirects),
  image: parser.image(slice.primary.slice_image),
  video: parser.link(slice.primary.slice_video),
  embed: parser.embed(slice.primary.slice_embed),
});
