import { IEmbed } from 'utils/types';
import { PrismicEmbed } from '../PrismicTypes';

export const parseEmbed = (embed: PrismicEmbed): IEmbed => {
  if (!embed) return null;

  return {
    height: embed.height ?? null,
    width: embed.width ?? null,
    embedUrl: embed.embed_url ?? null,
    type: embed.type ?? null,
    version: embed.version ?? null,
    title: embed.title ?? null,
    authorName: embed.author_name ?? null,
    authorUrl: embed.author_url ?? null,
    providerName: embed.provider_name ?? null,
    providerUrl: embed.provider_url ?? null,
    cacheAge: embed.cache_age ?? null,
    thumbnailUrl: embed.thumbnail_url ?? null,
    thumbnailWidth: embed.thumbnail_width ?? null,
    thumbnailHeight: embed.thumbnail_height ?? null,
    html: embed.html ?? null,
  };
};
