import { RichTextBlock } from 'prismic-reactjs';
import { parseLink } from './parseLink';

export const parseRichText = (
  richtext: RichTextBlock[] = [],
  redirects?: Array<{ from: string; to: string }>
): RichTextBlock[] => {
  const urlFromSpanData = (spanData, redirects) => {
    switch (spanData.link_type) {
      case 'Media': {
        return parseLink(
          {
            _linkType: 'Link.file',
            url: spanData?.url,
            name: spanData?.name,
            size: parseInt(spanData?.size, 10),
          },
          redirects
        ).href;
      }
      default: {
        return parseLink(
          {
            _linkType: 'Link.document',
            _meta: {
              uid: spanData.uid,
              type: spanData.type,
              lang: spanData.lang,
            },
          },
          redirects
        ).href;
      }
    }
  };

  return (
    richtext?.map((section) => {
      const spans =
        section.spans?.map((span) => {
          if (span.type !== 'hyperlink' && span.data?.link_type !== 'Document') return span;

          const url = urlFromSpanData(span.data, redirects) ?? null;

          return {
            ...span,
            data: {
              ...span.data,
              slug: url,
            },
          };
        }) ?? null;
      return {
        ...section,
        ...(spans && { spans }),
      };
    }) ?? null
  );
};

/*

*/
