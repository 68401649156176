import gql from 'graphql-tag';
import * as Prismic from '@prismicio/client';

import { config } from 'utils/config';

const GRAPHQL_API_URL = `https://${config.prismic.repository}.cdn.prismic.io/graphql`;

export const PrismicClient = Prismic.createClient(
  `https://${config.prismic.repository}.cdn.prismic.io/api/v2`,
  {
    ...(config.prismic.apiKey && { accessToken: config.prismic.apiKey }),
  }
);

// @ts-ignore
export async function fetchAPI(query: string, { previewData, variables } = {}) {
  try {
    const prismicRef = await PrismicClient.getMasterRef();

    if (config.prismic.release && !config.prismic.apiKey) {
      throw new Error(
        `[PRISMIC CLIENT ERROR] You need to provide an "accessToken" to use a release\nCreate or copy from: https://${config.prismic.repository}.prismic.io/settings/apps/\n`
      );
    }

    // Fetch the latest timeline ref for a release
    let releaseData: any | null = null;

    try {
      releaseData = config.prismic.release
        ? await PrismicClient.getReleaseByID(config.prismic.release)
        : null;
    } catch (error) {
      console.error(
        `[PRISMIC CLIENT ERROR] Couldn't find a release ref. Make sure the accessToken has access to master+releases: https://${config.prismic.repository}.prismic.io/settings/apps/\n`
      );
    }

    const url = `${GRAPHQL_API_URL}?query=${encodeURIComponent(
      query.replace(/\s+/g, ' ')
    )}&variables=${variables ? encodeURIComponent(JSON.stringify(variables)) : ''}`;

    const res = await fetch(url, {
      headers: {
        'Prismic-Ref': previewData?.ref ?? releaseData?.ref ?? prismicRef.ref,
        'Content-Type': 'application/json',
        ...(config.prismic.apiKey && { Authorization: `Token ${config.prismic.apiKey}` }),
      },
    });

    const json = await res.json();

    if (res.status !== 200) {
      const errors = json.errors?.map((error) => error.message) ?? [];
      throw new Error(`Failed to fetch API ${url}\n${errors?.join('\n\n')}`);
    }

    if (json.errors) {
      // Check if the error is because there are no split pages
      if (!json.errors.find((error) => error.path?.includes('split_page'))) {
        const errors = json.errors?.map((error) => error.message) ?? [];
        throw new Error(`Failed to fetch API ${url}\n${errors?.join('\n\n')}`);
      }
    }

    return json.data;
  } catch (err) {
    process.env.NODE_ENV !== 'production' && console.error(err);

    return null;
  }
}

export const linkFragment = gql`
  fragment Link on _Linkable {
    __typename
    _linkType

    ... on _Document {
      _meta {
        id
        uid
        type
        lang
      }
    }

    ... on _ExternalLink {
      url
      target
    }

    ... on _ImageLink {
      name
      url
      size
    }

    ... on _FileLink {
      name
      url
      size
    }
  }
`;

export interface IPrismicObject {
  _meta?: {
    uid?: string;
  };
}
