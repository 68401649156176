import gql from 'graphql-tag';
import { IBaseSlice, SliceTypes } from '..';
import { IEmbed, IImage, ILink } from 'utils/types';
import { PrismicEmbed, PrismicImage, PrismicLink } from '../../utils/PrismicTypes';
import { parseEmbed, parseImage, parseLink, parseString } from '../../utils/parsers';

export interface ISplitPageSection extends IBaseSlice {
  color: string;
  logoColor: string;
  image: IImage;
  alignment: 'top' | 'center' | 'bottom' | string;
  video: ILink;
  embed: IEmbed;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_color
        slice_logo_color
        slice_image
        slice_alignment
        slice_video { ...Link }
        slice_embed
      }
    }
  }
`;

type Props = {
  primary: {
    slice_color: string;
    slice_logo_color: string;
    slice_image: PrismicImage;
    slice_alignment: string;
    slice_video: PrismicLink;
    slice_embed: PrismicEmbed;
  };
};

export const parse = (slice: Props): ISplitPageSection => ({
  type: SliceTypes.SplitPageSection,
  color: parseString(slice.primary.slice_color),
  logoColor: parseString(slice.primary.slice_logo_color),
  image: parseImage(slice.primary.slice_image),
  alignment: slice.primary.slice_alignment || null,
  video: parseLink(slice.primary.slice_video),
  embed: parseEmbed(slice.primary.slice_embed),
});
