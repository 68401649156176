import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IBaseSlice, SliceTypes } from '..';
import { fetchInvestorInformations, parseInvestorInformations } from '../../investorInformation';
import { parser } from '../../utils/parsers';

export interface ISales extends IBaseSlice {
  tagline: string;
  title: string;
  caption: RichTextBlock[];
  items: Array<{
    title: string;
    months: Array<{
      month: string;
      standalone: string;
      subsidiaries: string;
      consolidated: string;
      yoyChange: string;
      ytd: string;
      yoyTotal: string;
    }>;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_tagline
        slice_title
        slice_caption
      }
    }
  }
`;

type NestedProps = {
  lang: string;
  limit?: number;
  previewData: boolean;
  redirects: Array<{ from: string; to: string }>;
};

export const fetchInvestorInformationsAndParse = async ({
  lang,
  limit,
  previewData,
  redirects = [],
}: NestedProps) => {
  const items = await fetchInvestorInformations(lang, '', previewData, (limit = 500));
  return parseInvestorInformations(items, redirects);
};

type Props = {
  primary: {
    slice_tagline: RichTextBlock[];
    slice_title: RichTextBlock[];
    slice_caption: RichTextBlock[];
  };
};

export const parse = (slice: Props, redirects?): ISales => ({
  type: SliceTypes.Sales,
  tagline: parser.string(slice.primary.slice_tagline),
  title: parser.string(slice.primary.slice_title),
  caption: parser.richText(slice.primary.slice_caption),
  items: [],
});
