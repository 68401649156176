import React from 'react';

import { Segment } from 'components/segment/Segment';

import { IBaseSlice, SliceTypes } from 'providers/prismic/slices';
import { IDocuments } from 'providers/prismic/slices/Documents/schema';

import { components } from 'providers/prismic/slices';

type Props = {
  slices: IBaseSlice[];
  isArticle?: boolean;
  isEvent?: boolean;
  isProduct?: boolean;
  isSplitPage?: boolean;
};

interface ISlice {
  slice: IBaseSlice;
  extras?: any[];
  trailing?: any[];
  allowBackground?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
}

const TODOSliceComponent = (props: any) => {
  console.log(`Missing slice component for "${props.type}":`, props);
  return null;
};

const mapComponent = (
  { slice, trailing = [], allowBackground }: ISlice,
  index: number,
  options?: any
) => {
  const Component = components[slice.type] || TODOSliceComponent;

  return (
    <Component
      key={index}
      {...slice}
      index={index}
      slice={slice}
      trailing={trailing}
      allowBackground={allowBackground}
      {...options}
    />
  );
};

const parse = (slices: any): ISlice[] => {
  const typeWithBackground = ['articles', 'services', 'quote'];

  return slices.reduce((prev: ISlice[], slice: any, index: number) => {
    const isFirst = index === 0;
    const isLast = index === slices.length - 1;
    const allowsBackground = typeWithBackground.indexOf(slice.type) !== -1;
    const backgroundBefore = index > 0 && prev[index - 1].allowBackground;

    const obj: ISlice = {
      slice,
      allowBackground: !isFirst && !isLast && !backgroundBefore && allowsBackground,
      isFirst,
      isLast,
    };

    return [...prev, obj];
  }, []);
};

const combineSlices = (slices: ISlice[]): ISlice[] => {
  const primaryTypes = [SliceTypes.Text, SliceTypes.Table, SliceTypes.Quote];
  const combineWithText = [
    SliceTypes.Buttons,
    SliceTypes.Documents,
    SliceTypes.Quote,
    SliceTypes.Text,
    SliceTypes.Infographic,
    SliceTypes.CookiePolicy,
  ];

  return slices.reduce((prev: ISlice[], current: ISlice) => {
    const prevSlice = prev.length > 0 && prev[prev.length - 1].slice;

    // Handle Documents
    if (
      prevSlice?.type === SliceTypes.Documents &&
      current.slice.type === prevSlice.type &&
      (current.slice as IDocuments).appearance === (prevSlice as IDocuments).appearance &&
      (current.slice as IDocuments).appearance === true &&
      (prevSlice as IDocuments).appearance === true
    ) {
      prev[prev.length - 1].trailing = [...(prev[prev.length - 1].trailing || []), current.slice];

      return prev;
    } else if (
      current.slice.type === SliceTypes.Documents &&
      (current.slice as IDocuments).appearance
    ) {
      return [...prev, current];
    }

    // Handle data
    // if (prevSlice && prevSlice.type === 'data' && current.slice.type === prevSlice.type) {
    //   prev[prev.length - 1].trailing = [...(prev[prev.length - 1].trailing || []), current.slice];

    //   return prev;
    // }

    const prevShouldCombine = prevSlice && primaryTypes.indexOf(prevSlice.type) !== -1;
    const isAcceptable = combineWithText.indexOf(current.slice.type) !== -1;
    const hasTitle = !!current.slice.title && current.slice.title !== '';

    if (prevShouldCombine && isAcceptable && !hasTitle) {
      prev[prev.length - 1].extras = [...(prev[prev.length - 1].extras || []), current];

      return prev;
    }

    return [...prev, current];
  }, []);
};

export const Slices = ({
  slices = [],
  isArticle = false,
  isEvent = false,
  isProduct = false,
  isSplitPage = false,
}: Props) => {
  const combined = combineSlices(parse(slices));
  const options = {
    isSlice: !isProduct,
    isSplitPage,
    isArticle,
    isProduct,
    isEvent,
  };

  return (
    <>
      {combined
        .map((slice, index) => {
          if (slice.extras) {
            return (
              <Segment isSplitPage isArticle>
                {mapComponent(slice, index, options)}
                {slice.extras.map((extra, key) => mapComponent(extra, key))}
              </Segment>
            );
          }

          return mapComponent(slice, index, options);
        })
        .filter((x) => !!x)}
    </>
  );
};
