import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IBaseSlice, SliceTypes } from '..';
import { IImage, ILink } from 'utils/types';
import { PrismicImage, PrismicLink } from '../../utils/PrismicTypes';
import { parser } from '../../utils/parsers';

export interface IPeople extends IBaseSlice {
  tagline: string;
  title: string;
  text: RichTextBlock[];
  items: Array<{
    name: string;
    position: string;
    image: IImage;
    email: string;
    phonenumber: string;
    bio: RichTextBlock[];
    altLink: ILink;
    link: ILink;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_tagline
        slice_title
        slice_text
      }
      fields {
        item_person {
          ... on Person {
            _meta {
              uid
              type
              lang
            }
            name
            position
            image
            phonenumber
            email
            bio
            alternate_page {
              ...Link
            }
          }
        }
      }
    }
  }
`;

type Props = {
  primary: {
    slice_tagline: RichTextBlock[];
    slice_title: RichTextBlock[];
    slice_text: RichTextBlock[];
  };
  fields: Array<{
    item_person: {
      _meta: {
        uid: string;
        type: string;
        lang: string;
      };
      name: RichTextBlock[];
      position: RichTextBlock[];
      image: PrismicImage;
      email: RichTextBlock[];
      phonenumber: RichTextBlock[];
      bio: RichTextBlock[];
      alternate_page: PrismicLink;
    };
  }>;
};

export const parse = (slice: Props, redirects): IPeople => ({
  type: SliceTypes.People,
  tagline: parser.string(slice.primary.slice_tagline),
  title: parser.string(slice.primary.slice_title),
  text: parser.richText(slice.primary.slice_text, redirects),
  items:
    slice.fields
      .filter((f) => !!f.item_person)
      .map(({ item_person }) => ({
        name: parser.string(item_person.name),
        position: parser.string(item_person.position),
        image: parser.image(item_person.image),
        phonenumber: parser.string(item_person.phonenumber),
        email: parser.string(item_person.email),
        bio: parser.richText(item_person.bio, redirects),
        altLink: parser.link(item_person.alternate_page, redirects),
        link: parser.linkFromMeta(item_person._meta, redirects),
      })) ?? null,
});
