import gql from 'graphql-tag';
import { print } from 'graphql';
import { RichTextBlock } from 'prismic-reactjs';

import { fetchAPI, IPrismicObject } from '.';
import { IImage } from 'utils/types';
import { generateSliceFragments, IBaseSlice, PageTypes, parseSlices } from './slices';
import { PrismicImage } from './utils/PrismicTypes';
import { parseDateTime, parseImage, parseLinkFromMeta, parseString } from './utils/parsers';

export interface IPrismicStory extends IPrismicObject {
  uid: string;
  title: string;
  date: string;
  location: string;
  image: IImage;
  caption: string;
  seo?: {
    title: string;
    description: string;
    image: IImage;
  };
  extract?: string;
  slices?: IBaseSlice[];
}

export type PrismicStories = {
  allStorys: {
    pageInfo?: {
      hasNextPage: boolean;
      endCursor: string;
    };
    edges: Array<{
      node: {
        title: RichTextBlock[];
        date: string;
        location: RichTextBlock[];
        image: PrismicImage;
        caption: RichTextBlock[];
        _meta: {
          uid: string;
          type: string;
          lang: string;
        };
      };
    }>;
  };
};
export const fetchStories = async (
  lang: string,
  endCursor: string = '',
  previewData: boolean,
  limit?: number
): Promise<PrismicStories> => {
  return await fetchAPI(
    print(gql`
      query LatestStories($lang: String!, $endCursor: String, $limit: Int) {
        allStorys(lang: $lang, sortBy: date_DESC, first: $limit, after: $endCursor) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              title
              date
              location
              image
              caption
              _meta {
                uid
                type
                lang
              }
            }
          }
        }
      }
    `),
    {
      previewData,
      variables: {
        lang,
        endCursor,
        limit,
      },
    }
  );
};

// Recursivly fetch all items in a list since prismic graphql endpoint only returns max 20 items
const fetchAllStories = async (
  lang: string,
  endCursor: string = '',
  previewData,
  limit?: number
): Promise<PrismicStories> => {
  const stories = await fetchStories(lang, endCursor, previewData, limit);

  if (stories.allStorys.pageInfo.hasNextPage && stories.allStorys.edges.length < limit) {
    const nestedList = await fetchAllStories(
      lang,
      stories.allStorys.pageInfo.endCursor,
      previewData,
      limit
    );
    return {
      allStorys: {
        edges: [...stories.allStorys.edges, ...nestedList.allStorys.edges],
      },
    };
  }
  return stories;
};

export const getAllStories = async ({
  previewData = false,
  lang = undefined,
  limit = 3,
  redirects = [],
} = {}): Promise<IPrismicStory[]> => {
  const items = await fetchAllStories(lang, '', previewData, limit);

  return parseStories(items, redirects, lang);
};

export async function getStoryBySlug(
  slug: string,
  { previewData = false, lang = undefined, redirects = [] }
): Promise<IPrismicStory> {
  const storySliceFragments = await generateSliceFragments(PageTypes.Story, 'StorySlices');

  const query = gql`
    query GetStory($slug: String!, $lang: String!) {
      story(uid: $slug, lang: $lang) {
        _meta {
          uid
        }

        title
        date
        location
        image
        caption
        seo_title
        seo_description
        seo_image

        body {
          ...StorySlices
        }
      }
    }
    ${storySliceFragments}
  `;

  const res = await fetchAPI(print(query), {
    previewData,
    variables: {
      slug,
      lang,
    },
  });

  if (!res || !res.story) {
    return null;
  }

  const data = res.story;

  const slices = await parseSlices(data.body || [], lang, previewData, redirects);

  return {
    uid: data._meta.uid || null,
    title: parseString(data.title),
    date: parseDateTime(data.date, undefined, lang),
    location: parseString(data.location),
    image: parseImage(data.image),
    caption: parseString(data.caption),
    seo: {
      title: parseString(data.seo_title),
      description: parseString(data.seo_description),
      image: parseImage(data.image),
    },
    extract: parseString(data.body?.find((slice) => slice.type === 'text')?.primary.slice_text),
    slices,
  };
}

export const parseStories = (items: PrismicStories, redirects, lang): IPrismicStory[] => {
  return (
    items?.allStorys?.edges?.map(({ node: item }) => ({
      uid: item._meta.uid || null,
      title: parseString(item.title),
      date: parseDateTime(item.date, undefined, lang),
      location: parseString(item.location),
      image: parseImage(item.image),
      caption: parseString(item.caption),
      link: parseLinkFromMeta(item._meta),
    })) ?? null
  );
};
