import gql from 'graphql-tag';
import { print } from 'graphql';
import { RichTextBlock } from 'prismic-reactjs';

import { fetchAPI, IPrismicObject, linkFragment } from '.';
import { ILink } from 'utils/types';
import { parseLink, parseString, parseRichText } from './utils/parsers';

export interface IPrismicVerification extends IPrismicObject {
  title: string;
  tagline: string;
  text: RichTextBlock[];
  acceptLabel: string;
  declineLabel: string;
  link: ILink;
}

export async function getVerification({
  previewData,
  lang,
  redirects = [],
}): Promise<IPrismicVerification> {
  const query = gql`
    query GetVerification($lang: String!) {
      allVerifications(lang: $lang) {
        edges {
          node {
            title
            tagline
            text
            accept_label
            decline_label
            decline_link {
              ...Link
            }
          }
        }
      }
    }
    ${linkFragment}
  `;

  const res = await fetchAPI(print(query), {
    previewData,
    variables: {
      lang,
    },
  });

  if (!res || res.allVerifications.edges.length === 0) {
    return null;
  }

  // There should always be a single "Verification" per language
  const data = res.allVerifications.edges[0].node;

  return {
    title: parseString(data.title),
    tagline: parseString(data.tagline),
    text: parseRichText(data.text, redirects),
    acceptLabel: parseString(data.accept_label),
    declineLabel: parseString(data.decline_label),
    link: parseLink(data.decline_link),
  };
}
