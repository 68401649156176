import React from 'react';
import classnames from 'classnames';

import s from './Segment.module.scss';

type Props = {
  isSplitPage?: boolean;
  isArticle?: boolean;
  children: React.ReactNode;
};

export const Segment = ({ isSplitPage, isArticle, children }: Props) => {
  return (
    <div
      className={classnames(s.segment, {
        [s.split]: isSplitPage,
        [s.article]: isArticle,
        [s.full]: !isSplitPage && !isArticle,
      })}
    >
      <div className={s.segment__container}>
        {React.Children.map(children, (component: JSX.Element, key) => (
          <div className={s.segment__item}>
            {React.cloneElement(component, { key, isSplitPage, isArticle })}
          </div>
        ))}
      </div>
    </div>
  );
};
