import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IImage } from 'utils/types';
import { IBaseSlice, SliceTypes } from '..';
import { PrismicImage } from '../../utils/PrismicTypes';
import { parser } from '../../utils/parsers';

export interface IGalleryGrid extends IBaseSlice {
  tagline: string;
  title: string;
  items: Array<{
    image: IImage;
    size: string;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_tagline
        slice_title
      }
      fields {
        item_image
        item_size
      }
    }
  }
`;

type Props = {
  primary: {
    slice_tagline: RichTextBlock[];
    slice_title: RichTextBlock[];
  };
  fields: Array<{
    item_image: PrismicImage;
    item_size: string;
  }>;
};

export const parse = (slice: Props, redirects): IGalleryGrid => ({
  type: SliceTypes.GalleryGrid,
  tagline: parser.string(slice.primary.slice_tagline),
  title: parser.string(slice.primary.slice_title),
  items: slice.fields?.map((field) => ({
    image: parser.image(field.item_image),
    size: parser.string(field.item_size),
  })),
});
