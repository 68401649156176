import gql from 'graphql-tag';
import { print } from 'graphql';
import { RichTextBlock } from 'prismic-reactjs';

import { fetchAPI, IPrismicObject, linkFragment } from '.';
import { IImage, ILink } from 'utils/types';
import { PrismicImage, PrismicLink } from './utils/PrismicTypes';
import { generateSliceFragments, IBaseSlice, PageTypes, parseSlices } from './slices';
import {
  parseImage,
  parseLink,
  parseString,
  parseRichText,
  parseLinkFromMeta,
} from './utils/parsers';

export interface IPrismicProduct extends IPrismicObject {
  uid: string;
  type: string;
  title: string;
  image: IImage;
  abRatedTo: string;
  ratedLabel: string;
  warning: boolean;
  link: ILink;
  hasRems: boolean;
  remsLink: ILink;
  pdfLabel: string;
  pdfLink: ILink;
  slices?: IBaseSlice[];
  brandImage: IImage;
  seo?: {
    title: string;
    description: string;
    image: IImage;
  };
  variants?: Array<{
    type?: string;
    ndc?: string;
    gcn?: string;
    form?: string;
    image?: IImage;
    gcnSeq?: string;
    strength?: string;
    size?: string;
  }>;
  detailsLink?: ILink;
}

export async function getProductBySlug(
  slug: string,
  { previewData = false, lang = undefined, redirects = [] }
): Promise<IPrismicProduct> {
  const productSliceFragments = await generateSliceFragments(PageTypes.Product, 'ProductSlices');

  const query = gql`
    query GetProduct($slug: String!, $lang: String!) {
      product(uid: $slug, lang: $lang) {
        _meta {
          uid
        }

        type
        title
        image
        ab_rated_to
        rated_label
        contains_boxed_warning

        brand_image

        seo_title
        seo_description
        seo_image

        link {
          ...Link
        }
        rems
        rems_link {
          ...Link
        }
        pdf_label
        pdf_link {
          ...Link
        }
        body {
          ...ProductSlices
        }
      }
    }
    ${productSliceFragments}
    ${linkFragment}
  `;

  const res = await fetchAPI(print(query), {
    previewData,
    variables: {
      slug,
      lang,
    },
  });

  if (!res || !res.product) {
    return null;
  }

  const data = res.product;

  const slices = await parseSlices(data.body || [], lang, previewData, redirects);

  return {
    uid: data._meta.uid || null,
    type: data.type,
    title: parseString(data.title),
    image: parseImage(data.image),
    abRatedTo: parseString(data.ab_rated_to),
    ratedLabel: parseString(data.rated_label),
    warning: data.contains_boxed_warning === 'Yes',
    link: parseLink(data.link),
    hasRems: data.rems || false,
    remsLink: parseLink(data.rems_link),
    pdfLabel: parseString(data.pdf_label),
    pdfLink: parseLink(data.pdf_link),
    brandImage: parseImage(data.brand_image),
    seo: {
      title: parseString(data.seo_title),
      description: parseString(data.seo_description),
      image: parseImage(data.seo_image),
    },
    slices,
  };
}

export type PrismicProducts = {
  allProducts: {
    pageInfo?: {
      hasNextPage: boolean;
      endCursor: string;
    };
    edges: Array<{
      node: {
        type: RichTextBlock[];
        title: RichTextBlock[];
        image: PrismicImage;
        ab_rated_to: RichTextBlock[];
        rated_label: RichTextBlock[];
        contains_boxed_warning: string;
        link: PrismicLink;
        rems: boolean;
        rems_link: PrismicLink;
        pdf_label: RichTextBlock[];
        pdf_link: PrismicLink;
        brand_image: PrismicImage;
        body: Array<{
          type: string;
          primary: {
            slice_ndc: RichTextBlock[];
            slice_gcn: RichTextBlock[];
            slice_form: RichTextBlock[];
            slice_image: PrismicImage;
            slice_gcn_seq: RichTextBlock[];
            slice_strength: RichTextBlock[];
            slice_package_size: RichTextBlock[];
          };
        }>;
        _meta: {
          uid: string;
          type: string;
          lang: string;
        };
      };
    }>;
  };
};

export const fetchProducts = async (
  lang: string,
  endCursor: string = '',
  previewData: boolean,
  limit?: number
): Promise<PrismicProducts> => {
  return await fetchAPI(
    print(gql`
      query LatestProducts($lang: String!, $endCursor: String, $limit: Int) {
        allProducts(lang: $lang, sortBy: title_ASC, first: $limit, after: $endCursor) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              type
              title
              image
              brand_image
              ab_rated_to
              rated_label
              contains_boxed_warning
              link {
                ...Link
              }
              rems
              rems_link {
                ...Link
              }
              pdf_label
              pdf_link {
                ...Link
              }
              body {
                ... on ProductBodyVariant {
                  type
                  primary {
                    slice_ndc
                    slice_gcn
                    slice_form
                    slice_image
                    slice_gcn_seq
                    slice_strength
                    slice_package_size
                  }
                }
              }
              _meta {
                uid
                type
                lang
              }
            }
          }
        }
      }
      ${linkFragment}
    `),
    {
      previewData,
      variables: {
        lang,
        endCursor,
        limit,
      },
    }
  );
};

export const getAllProducts = async ({
  previewData = false,
  lang = undefined,
  limit = 3,
  redirects = [],
} = {}): Promise<IPrismicProduct[]> => {
  const items = await fetchAllProducts(lang, '', previewData, limit);

  return parseProducts(items, redirects);
};

// Recursivly fetch all items in a list since prismic graphql endpoint only returns max 20 items
export const fetchAllProducts = async (
  lang: string,
  endCursor: string = '',
  previewData,
  limit?: number
): Promise<PrismicProducts> => {
  const products = await fetchProducts(lang, endCursor, previewData, limit);

  if (products.allProducts.pageInfo.hasNextPage && products.allProducts.edges.length < limit) {
    const nestedList = await fetchAllProducts(
      lang,
      products.allProducts.pageInfo.endCursor,
      previewData,
      limit
    );
    return {
      allProducts: {
        edges: [...products.allProducts.edges, ...nestedList.allProducts.edges],
      },
    };
  }
  return products;
};

export const parseProducts = (items: PrismicProducts, redirects): IPrismicProduct[] => {
  return (
    items?.allProducts?.edges.map(({ node: item }) => ({
      uid: item._meta.uid || null,
      type: parseString(item.type),
      title: parseString(item.title),
      image: parseImage(item.image),
      abRatedTo: parseString(item.ab_rated_to),
      ratedLabel: parseString(item.rated_label),
      warning: item.contains_boxed_warning === 'Yes',
      link: parseLink(item.link, redirects),
      hasRems: item.rems || false,
      remsLink: parseLink(item.rems_link, redirects),
      pdfLabel: parseString(item.pdf_label),
      pdfLink: parseLink(item.pdf_link, redirects),
      brandImage: parseImage(item.brand_image),
      variants: item.body
        .filter((variant) => variant.type === 'variant')
        .map((variant) => ({
          type: variant.type,
          ndc: parseString(variant.primary.slice_ndc),
          gcn: parseString(variant.primary.slice_gcn),
          form: parseString(variant.primary.slice_form),
          image: parseImage(variant.primary.slice_image),
          gcnSeq: parseString(variant.primary.slice_gcn_seq),
          strength: parseString(variant.primary.slice_strength),
          size: parseString(variant.primary.slice_package_size),
        })),
      detailsLink: parseLinkFromMeta(item._meta, redirects),
    })) ?? null
  );
};
