import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IBaseSlice, SliceTypes } from '..';
import { parser } from '../../utils/parsers';

export interface IPackages extends IBaseSlice {
  title: string;
  tagline: string;
  items: Array<{
    vnr: string;
    strength: string;
    size: string;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_title
        slice_tagline
      }
      fields {
        item_vnr
        item_strength
        item_size
      }
    }
  }
`;

type Props = {
  primary: {
    slice_title: RichTextBlock[];
    slice_tagline: RichTextBlock[];
  };
  fields: Array<{
    item_vnr: RichTextBlock[];
    item_strength: RichTextBlock[];
    item_size: RichTextBlock[];
  }>;
};

export const parse = (slice: Props, redirects): IPackages => ({
  type: SliceTypes.Packages,
  title: parser.string(slice.primary.slice_title),
  tagline: parser.string(slice.primary.slice_tagline),
  items: slice.fields.map((field) => ({
    vnr: parser.string(field.item_vnr),
    strength: parser.string(field.item_strength),
    size: parser.string(field.item_size),
  })),
});
