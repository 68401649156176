import gql from 'graphql-tag';
import { RichTextBlock } from 'prismic-reactjs';
import { IBaseSlice, SliceTypes } from '..';
import { fetchAllEvents, IEvent, parseEvents } from '../../events';

export interface IEvents extends IBaseSlice {
  // title: string;
  items: IEvent[];
}

export const fragment = (name: string, type: string, fragmentName: string) => {
  return gql`
    fragment ${fragmentName || name} on ${type} {
        ... on ${type}${name} {
          type
        }
      }
    `;
};

type NestedProps = {
  lang: string;
  limit?: number;
  previewData: boolean;
  redirects: Array<{ from: string; to: string }>;
};

export const fetchEventsAndParse = async ({
  lang,
  limit,
  previewData,
  redirects = [],
}: NestedProps) => {
  const items = await fetchAllEvents(lang, undefined, previewData, limit);

  return parseEvents(items, redirects, lang);
};

type Props = {
  primary: {
    slice_title: RichTextBlock[];
  };
};

export const parse = (slice: Props): IEvents => ({
  type: SliceTypes.Events,
  // title: RichText.asText(slice.primary.slice_title),
  items: [],
});
