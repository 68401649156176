import gql from 'graphql-tag';
import { RichTextBlock } from 'prismic-reactjs';
import { IImage, ILink } from 'utils/types';
import { PrismicLink, PrismicImage } from '../../utils/PrismicTypes';
import { IBaseSlice, SliceTypes } from '..';
import { parser } from '../../utils/parsers';

export interface ICatalogAlmatica extends IBaseSlice {
  tagline: string;
  title: string;
  text: RichTextBlock[];
  items: Array<{
    title?: string;
    warning?: boolean;
    image?: IImage;
    label?: string;
    link?: ILink;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_tagline
        slice_title
        slice_text
      }
      fields {
        item_title
        item_warning
        item_image
        item_label
        item_link {
          ...Link
        }
      }
    }
  }
`;

type Props = {
  primary: {
    slice_tagline: RichTextBlock[];
    slice_title: RichTextBlock[];
    slice_text: RichTextBlock[];
  };
  fields: Array<{
    item_title: RichTextBlock[];
    item_warning: boolean;
    item_image: PrismicImage;
    item_label: RichTextBlock[];
    item_link?: PrismicLink;
  }>;
};

export const parse = (slice: Props, redirects): ICatalogAlmatica => ({
  type: SliceTypes.CatalogAlmatica,
  tagline: parser.string(slice.primary.slice_tagline),
  text: parser.richText(slice.primary.slice_text, redirects),
  title: parser.string(slice.primary.slice_title),
  items: slice.fields?.map((field) => ({
    title: parser.string(field.item_title, redirects),
    warning: field.item_warning || false,
    image: parser.image(field.item_image),
    label: parser.string(field.item_label, redirects),
    link: parser.link(field.item_link),
  })),
});
