import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IImage, ILink } from 'utils/types';
import { IBaseSlice, SliceTypes } from '..';
import { PrismicImage, PrismicLink } from '../../utils/PrismicTypes';
import { parser } from '../../utils/parsers';

export interface IMediaList extends IBaseSlice {
  title: string;
  archive: ILink;
  aspect: string;
  items: Array<{
    image: IImage;
    title: string;
    subline: string;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_title
        slice_archive { ...Link }
        slice_aspect
      }
      fields {
        item_image
        item_title
        item_subline
      }
    }
  }
`;

type Props = {
  primary: {
    slice_title: RichTextBlock[];
    slice_archive: PrismicLink;
    slice_aspect: string;
  };
  fields: Array<{
    item_image: PrismicImage;
    item_title: RichTextBlock[];
    item_subline: RichTextBlock[];
  }>;
};

export const parse = (slice: Props, redirects): IMediaList => ({
  type: SliceTypes.MediaList,
  title: parser.string(slice.primary.slice_title),
  archive: parser.link(slice.primary.slice_archive, redirects),
  aspect: parser.string(slice.primary.slice_aspect),
  items: slice.fields.map((field) => ({
    image: parser.image(field.item_image),
    title: parser.string(field.item_title),
    subline: parser.string(field.item_subline),
  })),
});
