import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IBaseSlice, SliceTypes } from '..';
import { fetchAllBasicProducts, parseBasicProducts } from '../../basicProduct';
import { PrismicImage, PrismicLink } from '../../utils/PrismicTypes';
import { IImage, ILink } from 'utils/types';
import { parser } from '../../utils/parsers';

export interface IIndexGrid extends IBaseSlice {
  title: string;
  tagline: string;
  text: RichTextBlock[];
  image: IImage;
  link: ILink;
  label: string;
  tags: string[];
  categories: string[];
  showAll: string;
  gridItems: Array<{
    image: IImage;
    title: string;
    text: RichTextBlock[];
    link: ILink;
    label: string;
  }>;
  items: Array<{
    tagline: string;
    title: string;
    intro: string;
    tags: string[];
    images: Array<{
      image: IImage;
    }>;
    detailsLink?: ILink;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_title
        slice_tagline
        slice_text
        slice_tags
        slice_image
        slice_categories
        slice_all
        slice_link { ...Link }
        slice_label
      }
      fields {
        item_image
        item_title
        item_description
        item_link { ...Link }
        item_label
      }
    }
  }
`;

type NestedProps = {
  lang: string;
  limit?: number;
  previewData: boolean;
  redirects: Array<{ from: string; to: string }>;
  tags?: string[];
};

export const fetchBasicProductsAndParse = async ({
  lang,
  limit,
  previewData,
  redirects = [],
  tags,
}: NestedProps) => {
  const items =
    tags.length > 0 && (await fetchAllBasicProducts(lang, '', previewData, limit, tags));
  return parseBasicProducts(items, redirects);
};

type Props = {
  primary: {
    slice_title: RichTextBlock[];
    slice_tagline: RichTextBlock[];
    slice_text: RichTextBlock[];
    slice_tags: RichTextBlock[];
    slice_image: PrismicImage;
    slice_link: PrismicLink;
    slice_label: RichTextBlock[];
    slice_categories: RichTextBlock[];
    slice_all: RichTextBlock[];
  };
  fields: Array<{
    item_image: PrismicImage;
    item_title: RichTextBlock[];
    item_description: RichTextBlock[];
    item_link: PrismicLink;
    item_label: RichTextBlock[];
  }>;
};

export const parse = (slice: Props, redirects): IIndexGrid => ({
  type: SliceTypes.IndexGrid,
  title: parser.string(slice.primary.slice_title),
  tagline: parser.string(slice.primary.slice_tagline, redirects),
  text: parser.richText(slice.primary.slice_text, redirects),
  image: parser.image(slice.primary.slice_image),
  link: parser.link(slice.primary.slice_link, redirects),
  label: parser.string(slice.primary.slice_label, redirects),
  tags: (parser.string(slice.primary?.slice_tags) || '')
    .split(',')
    .map((str) => str.trim())
    .filter((x) => !!x),
  categories: (parser.string(slice.primary?.slice_categories) || '')
    .split(',')
    .map((str) => str.trim())
    .filter((x) => !!x),
  showAll: parser.string(slice.primary.slice_all),
  gridItems: slice.fields.map((field) => ({
    image: parser.image(field.item_image),
    title: parser.string(field.item_title),
    text: parser.richText(field.item_description, redirects),
    link: parser.link(field.item_link, redirects),
    label: parser.string(field.item_label),
  })),
  items: [],
});
