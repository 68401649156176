import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IEmbed, IImage } from 'utils/types';
import { IBaseSlice, SliceTypes } from '..';
import { PrismicEmbed, PrismicImage } from '../../utils/PrismicTypes';
import { parser } from '../../utils/parsers';

export interface IVideo extends IBaseSlice {
  tagline: string;
  title: string;
  embed: IEmbed;
  placeholder: IImage;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_tagline
        slice_title
        slice_embed
        slice_placeholder
      }
    }
  }
`;

export const customArticleFragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_embed
        slice_placeholder
      }
    }
  }
`;

type Props = {
  primary: {
    slice_tagline: RichTextBlock[];
    slice_title: RichTextBlock[];
    slice_embed: PrismicEmbed;
    slice_placeholder: PrismicImage;
  };
};

export const parse = (slice: Props): IVideo => ({
  type: SliceTypes.Video,
  tagline: parser.string(slice.primary.slice_tagline),
  title: parser.string(slice.primary.slice_title),
  embed: parser.embed(slice.primary.slice_embed),
  placeholder: parser.image(slice.primary.slice_placeholder),
});
