import { IImage } from 'utils/types';
import { PrismicImage, PrismicImagePart } from '../PrismicTypes';

export const parseImage = (image: PrismicImage): IImage => {
  if (!image) {
    return null;
  }

  const {
    url,
    alt,
    copyright,
    dimensions: { width, height },
    ...sizes
  } = image;

  const parseImageParts = (part: PrismicImagePart) => {
    if (!part) return null;
    return {
      url: part.url || url,
      width: part.dimensions.width || width,
      height: part.dimensions.height || height,
      alt: part.alt || alt,
      ...((part.copyright || copyright) && { copyright: part.copyright || copyright }),
    };
  };

  const sizeOptions = Object.fromEntries(
    Object.entries(sizes).map(([key, value]) => {
      return [key.toLowerCase(), parseImageParts(value)];
    })
  );

  return {
    url,
    alt: alt ?? '',
    width,
    height,
    ...(copyright && { copyright }),
    ...sizeOptions,
  };
};
