import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IImage } from 'utils/types';
import { IBaseSlice, SliceTypes } from '..';
import { PrismicImage } from '../../utils/PrismicTypes';
import { parser } from '../../utils/parsers';

export interface IInfographics extends IBaseSlice {
  title: string;
  text: RichTextBlock[];
  items: Array<{
    image: IImage;
    number: string;
    text: RichTextBlock[];
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_title
        slice_text
      }
      fields {
        item_image
        item_number
        item_text
      }
    }
  }
`;

type Props = {
  primary: {
    slice_title: RichTextBlock[];
    slice_text: RichTextBlock[];
  };
  fields: Array<{
    slice_image: PrismicImage;
    item_number: RichTextBlock[];
    item_text: RichTextBlock[];
  }>;
};

export const parse = (slice: Props, redirects): IInfographics => ({
  type: SliceTypes.Infographics,
  title: parser.string(slice.primary.slice_title),
  text: parser.richText(slice.primary.slice_text, redirects),
  items: slice.fields.map((field) => ({
    image: parser.image(field.slice_image),
    number: parser.string(field.item_number),
    text: parser.richText(field.item_text),
  })),
});
