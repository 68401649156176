import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import _kebabCase from 'lodash/kebabCase';
import { IImage } from 'utils/types';
import { IBaseSlice, SliceTypes } from '..';
import { PrismicImage } from '../../utils/PrismicTypes';
import { parser } from '../../utils/parsers';

export interface IOffices extends IBaseSlice {
  title: string;
  image: IImage;
  display: string;
  items: Array<{
    title: string;
    text: RichTextBlock[];
    address: RichTextBlock[];
    hasAddress?: boolean;
    phone: string;
    fax: string;
    email: string;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_title
        slice_image
        slice_display
      }
      fields {
        item_title
        item_text
        item_address
        item_phone
        item_fax
        item_email
      }
    }
  }
`;

type Props = {
  primary: {
    slice_title: RichTextBlock[];
    slice_image: PrismicImage;
    slice_display: string;
  };
  fields: Array<{
    item_title: RichTextBlock[];
    item_text: RichTextBlock[];
    item_address: RichTextBlock[];
    item_phone: RichTextBlock[];
    item_fax: RichTextBlock[];
    item_email: RichTextBlock[];
  }>;
};

export const parse = (slice: Props, redirects): IOffices => ({
  type: SliceTypes.Offices,
  title: parser.string(slice.primary.slice_title),
  image: parser.image(slice.primary.slice_image),
  display: _kebabCase(slice.primary.slice_display ?? 'Two columns featured'),
  items: slice.fields.map((field) => ({
    title: parser.string(field.item_title),
    text: parser.richText(field.item_text, redirects),
    address: parser.richText(field.item_address, redirects),
    hasAddress: parser.string(field.item_address)?.length > 0 || false,
    phone: parser.string(field.item_phone),
    fax: parser.string(field.item_fax),
    email: parser.string(field.item_email),
  })),
});
