import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IBaseSlice, SliceTypes } from '..';
import { ILink } from 'utils/types';
import { PrismicLink } from '../../utils/PrismicTypes';
import { parser } from '../../utils/parsers';

export interface IRelated extends IBaseSlice {
  mainLinkText: string;
  mainLink: ILink;
  items: Array<{
    title: string;
    text: RichTextBlock[];
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_main_link { ...Link }
        slice_main_link_text
      }
      fields {
        item_title
        item_text
      }
    }
  }
`;

type Props = {
  primary: {
    slice_main_link_text: RichTextBlock[];
    slice_main_link: PrismicLink;
  };
  fields: Array<{
    item_title: RichTextBlock[];
    item_text: RichTextBlock[];
  }>;
};

export const parse = (slice: Props, redirects): IRelated => ({
  type: SliceTypes.Related,
  mainLinkText: parser.string(slice.primary.slice_main_link_text),
  mainLink: parser.link(slice.primary.slice_main_link, redirects),
  items: slice.fields?.map((field) => ({
    title: parser.string(field.item_title),
    text: parser.richText(field.item_text, redirects),
  })),
});
