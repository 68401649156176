import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { zhCN, is, ko } from 'date-fns/locale';

export enum DateFormat {
  Date = 'dd MMMM yyyy',
  DateTime = 'dd MMMM yyyy, h:mm aaa',
}

export const parseDateTime = (
  str,
  dateFormat: DateFormat | string = DateFormat.Date,
  lang?: string
): string => {
  if (!str) return null;

  return format(parseISO(str), dateFormat, {
    locale: !lang
      ? undefined
      : {
          'en-us': undefined,
          'zh-tw': zhCN,
          'is-is': is,
          'ko-kr': ko,
        }[lang],
  });
};
