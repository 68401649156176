import { print } from 'graphql';
import gql from 'graphql-tag';
import { RichTextBlock } from 'prismic-reactjs';

import { fetchAPI, linkFragment } from '.';
import { IImage, ILink, IEmbed } from 'utils/types';
import { IBaseSlice, PageTypes, parseSlices } from './slices';
import { RichText } from 'components/rich-text/RichText';
import { generateSliceFragments } from './slices';
import {
  parseDateTime,
  parseImage,
  parseLink,
  parseLinkFromMeta,
  parseString,
} from './utils/parsers';
import { PrismicImage, PrismicLink } from './utils/PrismicTypes';
import { DateFormat } from './utils/parsers/parseDateTime';

export interface IEvent {
  id?: string;
  uid?: string;
  title?: string;
  image?: IImage;
  video?: IEmbed;
  investorPresentation?: ILink;
  startDate?: string;
  endDate?: string;
  rawStartDate?: string;
  rawEndDate?: string;
  shareholderLetter?: ILink;
  webcast?: ILink;
  address?: RichTextBlock[];
  addressString?: string;
  booth?: string;
  seo?: {
    title?: string;
    description?: string;
    image?: IImage | null;
  };
  link?: ILink;
  altLink?: ILink;
  slices?: IBaseSlice[];
}

export type PrismicEvents = {
  allEvents: {
    totalCount: number;
    pageInfo?: {
      hasNextPage: boolean;
      endCursor: string;
    };
    edges: Array<{
      node: {
        title: RichTextBlock[];
        image: PrismicImage;
        video: IEmbed;
        investor_presentation: PrismicLink;
        alternate_link: PrismicLink;
        start_date: string;
        end_date: string;
        shareholder_letter: PrismicLink;
        webcast: PrismicLink;
        address: RichTextBlock[];
        booth: RichTextBlock[];
        seo_title: RichTextBlock[];
        seo_description: RichTextBlock[];
        seo_image: PrismicImage | null;
        _meta: {
          id: string;
          uid: string;
          type: string;
          lang: string;
        };
      };
    }>;
  };
};

export const fetchEvents = async (
  lang: string,
  endCursor: string = '',
  previewData: boolean,
  limit?: number
): Promise<PrismicEvents> => {
  return await fetchAPI(
    print(gql`
      query LatestEvents($lang: String!, $endCursor: String, $limit: Int) {
        allEvents(lang: $lang, sortBy: start_date_DESC, first: $limit, after: $endCursor) {
          totalCount
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              title
              image
              video

              investor_presentation {
                ...Link
              }

              alternate_link {
                ...Link
              }

              start_date
              end_date

              shareholder_letter {
                ...Link
              }
              webcast {
                ...Link
              }
              address
              booth

              seo_title
              seo_image
              seo_description

              _meta {
                id
                uid
                type
                lang
              }
            }
          }
        }
      }
      ${linkFragment}
    `),
    {
      previewData,
      variables: {
        lang,
        endCursor,
        limit,
      },
    }
  );
};

// Recursivly fetch all items in a list since prismic graphql endpoint only returns max 20 items
export const fetchAllEvents = async (
  lang: string,
  endCursor: string = '',
  previewData,
  limit?: number,
  tags?: string[]
): Promise<PrismicEvents> => {
  const articles = await fetchEvents(lang, endCursor, previewData, limit);

  if (articles?.allEvents.pageInfo.hasNextPage && articles?.allEvents.edges.length < limit) {
    const nestedList = await fetchAllEvents(
      lang,
      articles.allEvents.pageInfo.endCursor,
      previewData,
      limit,
      tags
    );
    return {
      allEvents: {
        totalCount: articles.allEvents.totalCount,
        edges: [...articles.allEvents.edges, ...nestedList.allEvents.edges],
      },
    };
  }
  return articles;
};

export const getAllEvents = async ({
  previewData = false,
  lang = undefined,
  limit = 3,
  redirects = [],
} = {}): Promise<IEvent[]> => {
  const items = await fetchAllEvents(lang, '', previewData, limit);

  return parseEvents(items, redirects, lang);
};

export async function getEventBySlug(
  slug: string,
  { previewData = '', lang = undefined, redirects = [] } = {}
): Promise<IEvent> {
  console.log('get event by slug', slug);
  const eventFragments = await generateSliceFragments(PageTypes.Event, 'EventSlices');

  console.log('eventFragments', eventFragments);

  const res = await fetchAPI(
    print(gql`
      query EventBySlug($slug: String!, $lang: String!) {
        event(uid: $slug, lang: $lang) {
          title
          image
          video

          investor_presentation {
            ...Link
          }
          start_date
          end_date

          shareholder_letter {
            ...Link
          }
          webcast {
            ...Link
          }
          address
          booth

          seo_title
          seo_image
          seo_description

          _meta {
            id
            uid
          }

          body {
            ...EventSlices
          }
        }
      }
      ${eventFragments}
      ${linkFragment}
    `),
    {
      previewData,
      variables: {
        slug,
        lang,
      },
    }
  );

  if (res.event === null) {
    return null;
  }

  const { event } = res;

  const slices = await parseSlices(event.body || [], lang, previewData, redirects);

  // console.log(JSON.stringify(listContent, null, 2));

  return {
    id: event._meta.id,
    uid: event._meta.uid,
    title: parseString(event.title),
    image: parseImage(event.image),
    video: event.video,
    investorPresentation: parseLink(event.investor_presentation, redirects),
    startDate: parseDateTime(event.start_date),
    endDate: parseDateTime(event.end_date),
    shareholderLetter: parseLink(event.shareholder_letter, redirects),
    webcast: parseLink(event.shareholder_letter, redirects),
    address: event.address,
    addressString: parseString(event.address),
    booth: parseString(event.booth),
    seo: {
      title: parseString(event.seo_title),
      description: parseString(event.seo_description),
      image: parseImage(event.seo_image),
    },
    slices: slices,
  };
}

export const parseEvents = (items: PrismicEvents, redirects, lang): IEvent[] => {
  return items?.allEvents?.edges.map(({ node: item }) => ({
    id: item._meta.id,
    uid: item._meta.uid,
    title: parseString(item.title),
    image: parseImage(item.image),
    video: item.video,
    investorPresentation: parseLink(item.investor_presentation, redirects),
    startDate: parseDateTime(item.start_date, undefined, lang),
    endDate: parseDateTime(item.end_date, undefined, lang),
    rawStartDate: parseDateTime(item.start_date, DateFormat.DateTime),
    rawEndDate: parseDateTime(item.end_date, DateFormat.DateTime),
    shareholderLetter: parseLink(item.shareholder_letter, redirects),
    webcast: parseLink(item.shareholder_letter, redirects),
    address: item.address,
    addressString: parseString(item.address),
    booth: parseString(item.booth),
    seo: {
      title: parseString(item.seo_title),
      description: parseString(item.seo_description),
      image: parseImage(item.seo_image),
    },
    link: parseLinkFromMeta(item._meta, redirects),
    altLink: parseLink(item.alternate_link, redirects),
  }));
};
