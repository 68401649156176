import gql from 'graphql-tag';
import { print } from 'graphql';
import { RichTextBlock } from 'prismic-reactjs';

import { fetchAPI, IPrismicObject, linkFragment } from '.';
import { IImage, ILink } from 'utils/types';
import { PrismicImage, PrismicLink } from './utils/PrismicTypes';
import { parseLink, parseString, parseRichText, parseImage } from './utils/parsers';

export interface IPrismicAlmajectProduct extends IPrismicObject {
  uid: string;
  tagline: string;
  title: RichTextBlock[];
  presentation: RichTextBlock[];
  referenceDrug: RichTextBlock[];
  therapeuticClass: RichTextBlock[];
  safetyDataSheet: ILink;
  barcodes: ILink;
  boxedWarning: boolean;
  sidenote: RichTextBlock[];
  itemNumbers: Array<{
    number: RichTextBlock[];
    strength: string;
    abc: string;
    cardinal: string;
    mckesson: string;
    md: string;
  }>;
  images: Array<{
    image: IImage;
  }>;
  unitsOfSale: Array<{
    physicalForm: string;
    unitOfSale: string;
    packSize: string;
    caseQuantity: string;
    minimumOrder: string;
  }>;
  variations: Array<{
    description: string;
    strength: string;
    concentration: string;
    vialSize: string;
    fillVolume: string;
    closureSize: string;
    latexFree: boolean;
    preservativeFree: boolean;
  }>;
  additionalInformation: Array<{
    label: RichTextBlock[];
    value: RichTextBlock[];
  }>;
  tags: string[];
}

export type PrismicAlmajectProducts = {
  allProduct_almajects: {
    pageInfo?: {
      hasNextPage: boolean;
      endCursor: string;
    };
    edges: Array<{
      node: {
        tagline: RichTextBlock[];
        title: RichTextBlock[];
        presentation: RichTextBlock[];
        reference_drug: RichTextBlock[];
        therapeutic_class: RichTextBlock[];
        safety_data_sheet: PrismicLink;
        bar_codes: PrismicLink;
        boxed_warning: boolean;
        sidenote: RichTextBlock[];

        item_numbers: Array<{
          item_number: RichTextBlock[];
          item_strength: string;
          item_abc: string;
          item_cardinal: string;
          item_mckesson: string;
          item_md: string;
        }>;

        images: Array<{
          item_image: PrismicImage;
        }>;

        units_of_sale: Array<{
          item_physical_form: string;
          item_unit: string;
          item_pack_size: string;
          item_case_quantity: string;
          item_minimum_order: string;
        }>;

        variations: Array<{
          item_description: string;
          item_strength: string;
          item_concentration: string;
          item_vial_size: string;
          item_volume: string;
          item_closure_size: string;
          item_latex_free: boolean;
          item_preservative_free: boolean;
        }>;

        additional_information: Array<{
          item_label: RichTextBlock[];
          item_value: RichTextBlock[];
        }>;

        _meta: {
          uid: string;
          type: string;
          lang: string;
          tags: string[];
        };
      };
    }>;
  };
};

export const fetchAlmajectProducts = async (
  lang: string,
  endCursor: string = '',
  previewData: boolean,
  limit?: number
): Promise<PrismicAlmajectProducts> => {
  return await fetchAPI(
    print(gql`
      query LatestProducts($lang: String!, $endCursor: String, $limit: Int) {
        allProduct_almajects(lang: $lang, sortBy: title_ASC, first: $limit, after: $endCursor) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              tagline
              title
              presentation
              reference_drug
              therapeutic_class
              safety_data_sheet {
                ...Link
              }
              bar_codes {
                ...Link
              }
              boxed_warning
              sidenote

              item_numbers {
                item_number
                item_strength
                item_abc
                item_cardinal
                item_mckesson
                item_md
              }

              images {
                item_image
              }

              units_of_sale {
                item_physical_form
                item_unit
                item_pack_size
                item_case_quantity
                item_minimum_order
              }

              variations {
                item_description
                item_strength
                item_concentration
                item_vial_size
                item_volume
                item_closure_size
                item_latex_free
                item_preservative_free
              }

              additional_information {
                item_label
                item_value
              }

              _meta {
                uid
                type
                lang
                tags
              }
            }
          }
        }
      }
      ${linkFragment}
    `),
    {
      previewData,
      variables: {
        lang,
        endCursor,
        limit,
      },
    }
  );
};

// Recursivly fetch all items in a list since prismic graphql endpoint only returns max 20 items
export const fetchAllAlmajectProducts = async (
  lang: string,
  endCursor: string = '',
  previewData,
  limit?: number
): Promise<PrismicAlmajectProducts> => {
  const products = await fetchAlmajectProducts(lang, endCursor, previewData, limit);

  if (
    products.allProduct_almajects.pageInfo.hasNextPage &&
    products.allProduct_almajects.edges.length < limit
  ) {
    const nestedList = await fetchAllAlmajectProducts(
      lang,
      products.allProduct_almajects.pageInfo.endCursor,
      previewData,
      limit
    );
    return {
      allProduct_almajects: {
        edges: [...products.allProduct_almajects.edges, ...nestedList.allProduct_almajects.edges],
      },
    };
  }
  return products;
};

export const getAllAlmajectProducts = async ({
  previewData = false,
  lang = undefined,
  limit = 3,
  redirects = [],
} = {}): Promise<IPrismicAlmajectProduct[]> => {
  const items = await fetchAllAlmajectProducts(lang, '', previewData, limit);
  return parseAlmajectProducts(items, redirects);
};

export async function getAlmajectProductBySlug(
  slug: string,
  { previewData = false, lang = undefined, redirects = [] }
): Promise<IPrismicAlmajectProduct> {
  const query = gql`
    query GetAlmajectProduct($slug: String!, $lang: String!) {
      product_almaject(uid: $slug, lang: $lang) {
        tagline
        title
        presentation
        reference_drug
        therapeutic_class
        safety_data_sheet {
          ...Link
        }
        bar_codes {
          ...Link
        }
        boxed_warning
        sidenote

        item_numbers {
          item_number
          item_strength
          item_abc
          item_cardinal
          item_mckesson
          item_md
        }

        images {
          item_image
        }

        units_of_sale {
          item_physical_form
          item_unit
          item_pack_size
          item_case_quantity
          item_minimum_order
        }

        variations {
          item_description
          item_strength
          item_concentration
          item_vial_size
          item_volume
          item_closure_size
          item_latex_free
          item_preservative_free
        }

        additional_information {
          item_label
          item_value
        }

        _meta {
          uid
          type
          lang
          tags
        }
      }
    }
    ${linkFragment}
  `;

  const res = await fetchAPI(print(query), {
    previewData,
    variables: {
      slug,
      lang,
    },
  });

  if (!res || !res.product_almaject) {
    return null;
  }

  const data = res.product_almaject;

  return {
    uid: data._meta.uid || null,
    tagline: parseString(data.tagline),
    title: parseRichText(data.title),
    presentation: parseRichText(data.presentation),
    referenceDrug: parseRichText(data.reference_drug),
    therapeuticClass: parseRichText(data.therapeutic_class),
    safetyDataSheet: parseLink(data.safety_data_sheet),
    barcodes: parseLink(data.bar_codes),
    boxedWarning: data.boxed_warning,
    sidenote: parseRichText(data.sidenote),

    itemNumbers: data.item_numbers.map((itemNumber) => ({
      number: parseRichText(itemNumber.item_number),
      strength: parseString(itemNumber.item_strength),
      abc: parseString(itemNumber.item_abc),
      cardinal: parseString(itemNumber.item_cardinal),
      mckesson: parseString(itemNumber.item_mckesson),
      md: parseString(itemNumber.item_md),
    })),

    images: data.images.map((image) => ({
      image: parseImage(image.item_image),
    })),

    unitsOfSale: data.units_of_sale.map((unitOfSale) => ({
      physicalForm: parseString(unitOfSale.item_physical_form),
      unitOfSale: parseString(unitOfSale.item_unit),
      packSize: parseString(unitOfSale.item_pack_size),
      caseQuantity: parseString(unitOfSale.item_case_quantity),
      minimumOrder: parseString(unitOfSale.item_minimum_order),
    })),

    variations: data.variations.map((variation) => ({
      description: parseString(variation.item_description),
      strength: parseString(variation.item_strength),
      concentration: parseString(variation.item_concentration),
      vialSize: parseString(variation.item_vial_size),
      fillVolume: parseString(variation.item_volume),
      closureSize: parseString(variation.item_closure_size),
      latexFree: variation.item_latex_free,
      preservativeFree: variation.item_preservative_free,
    })),

    additionalInformation: data.additional_information.map((info) => ({
      label: parseRichText(info.item_label),
      value: parseRichText(info.item_value),
    })),

    tags: data._meta.tags,
  };
}

export const parseAlmajectProducts = (
  items: PrismicAlmajectProducts,
  redirects
): IPrismicAlmajectProduct[] => {
  return (
    items?.allProduct_almajects?.edges.map(({ node: item }) => ({
      uid: item._meta.uid || null,
      tagline: parseString(item.tagline),
      title: parseRichText(item.title),
      presentation: parseRichText(item.presentation),
      referenceDrug: parseRichText(item.reference_drug),
      therapeuticClass: parseRichText(item.therapeutic_class),
      safetyDataSheet: parseLink(item.safety_data_sheet),
      barcodes: parseLink(item.bar_codes),
      boxedWarning: item.boxed_warning,
      sidenote: parseRichText(item.sidenote),

      itemNumbers: item.item_numbers.map((itemNumber) => ({
        number: parseRichText(itemNumber.item_number),
        strength: parseString(itemNumber.item_strength),
        abc: parseString(itemNumber.item_abc),
        cardinal: parseString(itemNumber.item_cardinal),
        mckesson: parseString(itemNumber.item_mckesson),
        md: parseString(itemNumber.item_md),
      })),

      images: item.images.map((image) => ({
        image: parseImage(image.item_image),
      })),

      unitsOfSale: item.units_of_sale.map((unitOfSale) => ({
        physicalForm: parseString(unitOfSale.item_physical_form),
        unitOfSale: parseString(unitOfSale.item_unit),
        packSize: parseString(unitOfSale.item_pack_size),
        caseQuantity: parseString(unitOfSale.item_case_quantity),
        minimumOrder: parseString(unitOfSale.item_minimum_order),
      })),

      variations: item.variations.map((variation) => ({
        description: parseString(variation.item_description),
        strength: parseString(variation.item_strength),
        concentration: parseString(variation.item_concentration),
        vialSize: parseString(variation.item_vial_size),
        fillVolume: parseString(variation.item_volume),
        closureSize: parseString(variation.item_closure_size),
        latexFree: variation.item_latex_free,
        preservativeFree: variation.item_preservative_free,
      })),

      additionalInformation: item.additional_information.map((info) => ({
        label: parseRichText(info.item_label),
        value: parseRichText(info.item_value),
      })),

      tags: item._meta.tags,
    })) ?? null
  );
};
