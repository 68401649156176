import { linkResolver } from 'utils/resolver';
import { ILink } from 'utils/types';
import { PrismicLink, PrismicMeta } from '../PrismicTypes';

export const parseLink = (
  prismicLink: PrismicLink,
  redirects?: Array<{ id?: string; from: string; to: string }>
): ILink => {
  if (!prismicLink) {
    return null;
  }

  switch (prismicLink._linkType) {
    case 'Link.document':
      return {
        id: prismicLink._meta.id || null,
        uid: prismicLink._meta.uid || null,
        href: linkResolver(
          {
            id: prismicLink._meta.id,
            uid: prismicLink._meta.uid,
            type: prismicLink._meta.type,
            lang: prismicLink._meta.lang,
          },
          redirects
        ),
      };

    case 'Link.file': {
      return {
        href: !!process.env.FILE_REWRITE
          ? `/files/${prismicLink.url.split('/').pop()}`
          : prismicLink.url,
        name: prismicLink.name,
        size: prismicLink.size,
      };
    }

    case 'Link.image': {
      return {
        href: prismicLink.url,
      };
    }

    case 'Link.web': {
      if (!prismicLink.url) return null;
      return {
        href: prismicLink.url,
        ...(prismicLink.target && { target: prismicLink.target }),
      };
    }

    default: {
      return {
        href: '/#unresolved',
      };
    }
  }
};

export const parseLinkFromMeta = (
  meta: PrismicMeta,
  redirects?: Array<{ from: string; to: string }>,
  { linkType }: { linkType: string } = { linkType: 'Link.document' }
) => {
  return parseLink({ _linkType: linkType, _meta: meta }, redirects);
};
