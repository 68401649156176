import gql from 'graphql-tag';
import { IBaseSlice, SliceTypes } from '..';
import { RichTextBlock } from 'prismic-reactjs';
import { PrismicLink, PrismicImage } from '../../utils/PrismicTypes';
import { ILink, IImage } from 'utils/types';
import { parser } from '../../utils/parsers';

export interface IHeroButtons extends IBaseSlice {
  tagline: string;
  sliceTitle: RichTextBlock[];
  text: RichTextBlock[];
  image: IImage;
  caption: RichTextBlock[];
  sideTitle: RichTextBlock[];
  items: Array<{
    link?: ILink;
    label?: string;
    linkType?: boolean;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_tagline
        slice_title
        slice_text
        slice_image
        slice_caption
        slice_sidetitle
      }
      fields {
        item_label
        item_link {
          ...Link
        }
        item_linktype
      }
    }
  }
`;

type Props = {
  primary: {
    slice_tagline: RichTextBlock[];
    slice_title: RichTextBlock[];
    slice_text: RichTextBlock[];
    slice_image: PrismicImage;
    slice_caption: RichTextBlock[];
    slice_sidetitle: RichTextBlock[];
  };
  fields: Array<{
    item_link?: PrismicLink;
    item_label?: RichTextBlock[];
    item_linktype?: boolean;
  }>;
};

export const parse = (slice: Props, redirects): IHeroButtons => ({
  type: SliceTypes.HeroButtons,
  tagline: parser.string(slice.primary.slice_tagline),
  sliceTitle: parser.richText(slice.primary.slice_title),
  text: parser.richText(slice.primary.slice_text),
  image: parser.image(slice.primary.slice_image),
  caption: parser.richText(slice.primary.slice_caption),
  sideTitle: parser.richText(slice.primary.slice_sidetitle),
  items: slice.fields?.map((field) => ({
    link: parser.link(field.item_link),
    label: parser.string(field.item_label),
    linkType: field.item_linktype || false,
  })),
});
