import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { ILink } from 'utils/types';
import { IBaseSlice, SliceTypes } from '..';
import { PrismicLink } from '../../utils/PrismicTypes';
import { parser } from '../../utils/parsers';

export interface IDocuments extends IBaseSlice {
  appearance: boolean;
  title: string;
  items: Array<{
    title: string;
    link: ILink;
    filename: string;
    filetype: string;
    filesize: number;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_appearance
        slice_title
      }
      fields {
        item_name
        item_document { ...Link }
      }
    }
  }
`;

export const customSplitPageFragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type

      fields {
        item_name
        item_document { ...Link }
      }
    }
  }
`;

type Props = {
  primary: {
    slice_appearance: boolean;
    slice_title: RichTextBlock[];
  };
  fields: Array<{
    item_name: RichTextBlock[];
    item_document: PrismicLink;
  }>;
};

export const parse = (slice: Props, redirects): IDocuments => ({
  type: SliceTypes.Documents,
  appearance: slice.primary?.slice_appearance || false,
  title: slice.primary ? parser.string(slice.primary.slice_title) : null,
  items: slice.fields.map((field) => ({
    title: parser.string(field.item_name),
    link: parser.link(field.item_document, redirects),
    filename: field.item_document?.name.replace(/\.[^/.]+$/, '') || '',
    filetype: (field.item_document?.name.match(/\.[0-9a-z]+$/i)[0] || '').substring(1) || '',
    filesize: field.item_document?.size || 0,
  })),
});
