import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IBaseSlice, SliceTypes } from '..';
import { parser } from '../../utils/parsers';

export interface IPipeline extends IBaseSlice {
  title: string;
  sliceTitle: RichTextBlock[];
  text: RichTextBlock[];
  footnotes: RichTextBlock[];
  steps: string;
  items: Array<{
    title: string;
    biosimilarCandidate: string;
    referenceBiologic: string;
    therapeuticArea: string;
    approvals: Array<{ name: string }>;
    currentStage: string;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_title
        slice_text
        slice_footnotes
        slice_steps
      }
      fields {
        item_pipeline {
          ... on Pipeline {
            title
            biosimilar_candidate
            reference_biologic
            therapeutic_area
            approvals {
              name
            }
            current_stage
          }
        }
      }
    }
  }
`;

type Props = {
  primary: {
    slice_title: RichTextBlock[];
    slice_text: RichTextBlock[];
    slice_footnotes: RichTextBlock[];
    slice_steps: RichTextBlock[];
  };
  fields: Array<{
    item_pipeline: {
      title: RichTextBlock[];
      biosimilar_candidate: RichTextBlock[];
      reference_biologic: RichTextBlock[];
      therapeutic_area: RichTextBlock[];
      approvals: Array<{ name: RichTextBlock[] }>;
      current_stage: string;
    };
  }>;
};

export const parse = (slice: Props, redirects): IPipeline => ({
  type: SliceTypes.Pipeline,
  title: parser.string(slice.primary.slice_title),
  sliceTitle: parser.richText(slice.primary.slice_title),
  text: parser.richText(slice.primary.slice_text),
  footnotes: parser.richText(slice.primary.slice_footnotes, redirects),
  steps: parser.string(slice.primary.slice_steps),
  items: slice.fields?.map(({ item_pipeline }) => ({
    title: parser.string(item_pipeline.title),
    biosimilarCandidate: parser.string(item_pipeline.biosimilar_candidate),
    referenceBiologic: parser.string(item_pipeline.reference_biologic),
    therapeuticArea: parser.string(item_pipeline.therapeutic_area),
    approvals: item_pipeline.approvals?.map(({ name }) => ({
      name: parser.string(name),
    })),
    currentStage: parser.string(item_pipeline.current_stage),
  })),
});
