import axios from 'axios';
import { ICheckboxField, IField, ISelectField, IWufooField } from './types';

export const fetch = async (id: string) => {
  const url = `${process.env.WUFOO_API_URL}/api/v3/forms/${id}/fields.json`;
  const { status, data } = await axios.get(url, {
    auth: {
      username: process.env.WUFOO_API_KEY,
      password: process.env.WUFOO_API_PASSWORD || 'footastic',
    },
  });

  return {
    fields: data.Fields.filter(
      (f) => ['EntryId', 'DateCreated', 'CreatedBy', 'LastUpdated', 'UpdatedBy'].indexOf(f.ID) < 0
    )
      .filter((f) => f.ClassNames !== 'hide')
      .map((field: IWufooField) => {
        const def: IField = {
          id: field.ID || '',
          isRequired: field.IsRequired === '1',
          defaultValue: field.DefaultVal || '',
          title: field.Title || '',
          type: field.Type || null,
        };

        // console.log(field);

        switch (field.Type) {
          case 'radio':
          case 'checkbox':
            return {
              ...def,
              choices: (field.Choices || field.SubFields || []).map((c) => ({
                checked: c.DefaultVal === '1',
                id: c.ID,
                label: c.Label,
              })),
            } as ICheckboxField;

          case 'select':
            return {
              ...def,
              choices: (field.Choices || field.SubFields || []).map((c) => ({
                label: c.Label,
              })),
            } as ISelectField;
        }

        return def;
      }),
  };
};
