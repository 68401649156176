import gql from 'graphql-tag';
import { print } from 'graphql';
import { RichTextBlock } from 'prismic-reactjs';

import { fetchAPI, IPrismicObject, linkFragment } from '.';
import { IImage, ILink } from 'utils/types';
import { PrismicImage, PrismicLink } from './utils/PrismicTypes';
import { generateSliceFragments, IBaseSlice, PageTypes, parseSlices } from './slices';
import {
  parseImage,
  parseLink,
  parseString,
  parseRichText,
  parseLinkFromMeta,
} from './utils/parsers';

export interface IPrismicProductIceland extends IPrismicObject {
  uid: string;
  tagline: string;
  title: string;
  description: RichTextBlock[];

  drugType: string;
  activeIngredient: string;
  form: string;
  strengths: string;
  sizes: string;

  warning: RichTextBlock[];
  adNumber: string;

  atc: string;

  images: Array<{
    image: IImage;
  }>;
  links: Array<{
    label: string;
    link: ILink;
  }>;
  tags: string[];
  slices?: IBaseSlice[];
  variants?: Array<{
    vnr: string;
    size: string;
    strength: string;
  }>;
  detailsLink?: ILink;
}

export type PrismicProductsIceland = {
  allProduct_icelands: {
    pageInfo?: {
      hasNextPage: boolean;
      endCursor: string;
    };
    edges: Array<{
      node: {
        tagline: RichTextBlock[];
        title: RichTextBlock[];
        description: RichTextBlock[];
        drug_type: RichTextBlock[];
        active_ingredient: RichTextBlock[];
        form: RichTextBlock[];
        strengths: RichTextBlock[];
        sizes: RichTextBlock[];
        warning: RichTextBlock[];
        advertising_number: RichTextBlock[];
        atc: RichTextBlock[];
        images: Array<{
          item_image: PrismicImage;
        }>;
        links: Array<{
          item_label: RichTextBlock[];
          item_link: PrismicLink;
        }>;
        body: Array<{
          type: string;
          fields: Array<{
            item_vnr: RichTextBlock[];
            item_size: RichTextBlock[];
            item_strength: RichTextBlock[];
          }>;
        }>;
        _meta: {
          uid: string;
          type: string;
          lang: string;
          tags: string[];
        };
      };
    }>;
  };
};

export const fetchProductsIceland = async (
  lang: string,
  endCursor: string = '',
  previewData: boolean,
  limit?: number
): Promise<PrismicProductsIceland> => {
  return await fetchAPI(
    print(gql`
      query LatestProducts($lang: String!, $endCursor: String, $limit: Int) {
        allProduct_icelands(lang: $lang, sortBy: title_ASC, first: $limit, after: $endCursor) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              tagline
              title
              description

              drug_type
              active_ingredient
              form
              strengths
              sizes

              warning
              advertising_number

              atc
              images {
                item_image
              }
              links {
                item_label
                item_link {
                  ...Link
                }
              }

              body {
                ... on Product_icelandBodyPackages {
                  type
                  fields {
                    item_vnr
                    item_size
                    item_strength
                  }
                }
              }
              _meta {
                uid
                type
                lang
                tags
              }
            }
          }
        }
      }
      ${linkFragment}
    `),
    {
      previewData,
      variables: {
        lang,
        endCursor,
        limit,
      },
    }
  );
};

// Recursivly fetch all items in a list since prismic graphql endpoint only returns max 20 items
export const fetchAllProductsIceland = async (
  lang: string,
  endCursor: string = '',
  previewData,
  limit?: number
): Promise<PrismicProductsIceland> => {
  const products = await fetchProductsIceland(lang, endCursor, previewData, limit);

  if (
    products.allProduct_icelands.pageInfo.hasNextPage &&
    products.allProduct_icelands.edges.length < limit
  ) {
    const nestedList = await fetchAllProductsIceland(
      lang,
      products.allProduct_icelands.pageInfo.endCursor,
      previewData,
      limit
    );
    return {
      allProduct_icelands: {
        edges: [...products.allProduct_icelands.edges, ...nestedList.allProduct_icelands.edges],
      },
    };
  }
  return products;
};

export const getAllProductsIceland = async ({
  previewData = false,
  lang = undefined,
  limit = 3,
  redirects = [],
} = {}): Promise<IPrismicProductIceland[]> => {
  const items = await fetchAllProductsIceland(lang, '', previewData, limit);

  return parseProductsIceland(items, redirects);
};

export async function getProductIcelandBySlug(
  slug: string,
  { previewData = false, lang = undefined, redirects = [] }
): Promise<IPrismicProductIceland> {
  const productIcelandSliceFragments = await generateSliceFragments(
    PageTypes.ProductIceland,
    'ProductIcelandSlices'
  );

  const query = gql`
    query GetProductIceland($slug: String!, $lang: String!) {
      product_iceland(uid: $slug, lang: $lang) {
        _meta {
          uid
          tags
        }

        tagline
        title
        description

        drug_type
        active_ingredient
        form
        strengths
        sizes

        warning
        advertising_number

        atc
        images {
          item_image
        }
        links {
          item_label
          item_link {
            ...Link
          }
        }

        body {
          ...ProductIcelandSlices
        }
      }
    }
    ${linkFragment}
    ${productIcelandSliceFragments}
  `;

  const res = await fetchAPI(print(query), {
    previewData,
    variables: {
      slug,
      lang,
    },
  });

  if (!res || !res.product_iceland) {
    return null;
  }

  const data = res.product_iceland;

  const slices = await parseSlices(data.body || [], lang, previewData, redirects);

  return {
    uid: data._meta.uid || null,
    tagline: parseString(data.tagline),
    title: parseString(data.title),
    description: parseRichText(data.description, redirects),

    drugType: parseString(data.drug_type),
    activeIngredient: parseString(data.active_ingredient),
    form: parseString(data.form),
    strengths: parseString(data.strengths),
    sizes: parseString(data.sizes),

    warning: parseRichText(data.warning, redirects),
    adNumber: parseString(data.advertising_number),

    atc: parseString(data.atc),
    images: data.images.map((image) => ({
      image: parseImage(image.item_image),
    })),
    links: data.links.map((link) => ({
      label: parseString(link.item_label),
      link: parseLink(link.item_link),
    })),
    tags: data._meta.tags,

    slices,
  };
}

export const parseProductsIceland = (
  items: PrismicProductsIceland,
  redirects
): IPrismicProductIceland[] => {
  return (
    items?.allProduct_icelands?.edges.map(({ node: item }) => ({
      uid: item._meta.uid || null,
      tagline: parseString(item.tagline),
      title: parseString(item.title),
      description: parseRichText(item.description, redirects),

      drugType: parseString(item.drug_type),
      activeIngredient: parseString(item.active_ingredient),
      form: parseString(item.form),
      strengths: parseString(item.strengths),
      sizes: parseString(item.sizes),

      warning: parseRichText(item.warning, redirects),
      adNumber: parseString(item.advertising_number),

      atc: parseString(item.atc),
      tags: item._meta.tags,
      images: item.images.map((image) => ({
        image: parseImage(image.item_image),
      })),
      links: item.links.map((link) => ({
        label: parseString(link.item_label),
        link: parseLink(link.item_link),
      })),
      variants: item.body?.find((slice) => slice.type === 'packages')
        ? item.body
            .find((slice) => slice.type === 'packages')
            .fields.map((variant) => ({
              vnr: parseString(variant.item_vnr),
              size: parseString(variant.item_size),
              strength: parseString(variant.item_strength),
            }))
        : null,

      detailsLink: parseLinkFromMeta(item._meta, redirects),
    })) ?? null
  );
};
