import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IImage, ILink } from 'utils/types';
import { IBaseSlice, SliceTypes } from '..';
import { fetchAllSimpleProducts, parseSimpleProducts } from '../../simpleProduct';
import { parser } from '../../utils/parsers';

export interface ICatalogSimple extends IBaseSlice {
  lang?: string;
  tagline: string;
  title: string;
  tags: string;
  items: Array<{
    category: string;
    title: string;
    ingredients: string;
    form: string;
    strength: string;
    therapeuticArea: string;
    timeline: string;
    scope: string;
    tags: string[];
    downloads: Array<{
      label: string;
      link: ILink;
    }>;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_tagline
        slice_title
        slice_tags
      }
    }
  }
`;

type NestedProps = {
  lang: string;
  limit?: number;
  previewData: boolean;
  redirects: Array<{ from: string; to: string }>;
};

export const fetchSimpleProductsAndParse = async ({
  lang,
  limit,
  previewData,
  redirects = [],
}: NestedProps) => {
  const items = await fetchAllSimpleProducts(lang, '', previewData, (limit = 500));
  return parseSimpleProducts(items, redirects);
};

type Props = {
  primary: {
    slice_tagline: RichTextBlock[];
    slice_title: RichTextBlock[];
    slice_tags: RichTextBlock[];
  };
};

export const parse = (slice: Props, redirects, lang): ICatalogSimple => ({
  type: SliceTypes.CatalogSimple,
  lang,
  tagline: parser.string(slice.primary.slice_tagline),
  title: parser.string(slice.primary.slice_title),
  tags: parser.string(slice.primary.slice_tags),
  items: [],
});
