import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { ILink } from 'utils/types';
import { IBaseSlice, SliceTypes } from '..';
import { PrismicLink } from '../../utils/PrismicTypes';
import { parser } from '../../utils/parsers';

export interface IContactBlock extends IBaseSlice {
  title: string;
  name: string;
  text: RichTextBlock[];
  phone: string;
  email: string;
  linkedin: ILink;
  link: ILink;
  linkTagline: string;
  linkTitle: string;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_title
        slice_name
        slice_text
        slice_phone
        slice_email
        slice_linkedin { ...Link }
        slice_link { ...Link }
        slice_linktagline
        slice_linktitle
      }
    }
  }
`;

type Props = {
  primary: {
    slice_title: RichTextBlock[];
    slice_name: RichTextBlock[];
    slice_text: RichTextBlock[];
    slice_phone: RichTextBlock[];
    slice_email: RichTextBlock[];
    slice_linkedin: PrismicLink;
    slice_link: PrismicLink;
    slice_linktagline: RichTextBlock[];
    slice_linktitle: RichTextBlock[];
  };
};

export const parse = (slice: Props, redirects?): IContactBlock => ({
  type: SliceTypes.ContactBlock,
  title: parser.string(slice.primary.slice_title),
  name: parser.string(slice.primary.slice_name),
  text: parser.richText(slice.primary.slice_text, redirects),
  phone: parser.string(slice.primary.slice_phone),
  email: parser.string(slice.primary.slice_email),
  linkedin: parser.link(slice.primary.slice_linkedin, redirects),
  link: parser.link(slice.primary.slice_link, redirects),
  linkTagline: parser.string(slice.primary.slice_linktagline),
  linkTitle: parser.string(slice.primary.slice_linktitle),
});
