import { RichText } from 'components/rich-text/RichText';
import { RichTextBlock } from 'prismic-reactjs';

export const parseString = (
  field: RichTextBlock[] | string,
  emptyIsNull: boolean = false // TODO: remove this param
): string | null => {
  if (typeof field === 'string') {
    return field === '' ? null : field;
  }

  const str = RichText.asText(field ?? [])?.trim();

  return str ?? null;
};
