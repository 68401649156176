import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { ILink } from 'utils/types';
import { IBaseSlice, SliceTypes } from '..';
import { PrismicLink } from '../../utils/PrismicTypes';
import { parser } from '../../utils/parsers';

export interface IPageShortcuts extends IBaseSlice {
  nextLabel: string;
  nextLink: ILink;
  previousLabel: string;
  previousLink: ILink;
  homeLabel: string;
  homeLink: ILink;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        next_label
        next_link {
          ...Link
        }
        previous_label
        previous_link {
          ...Link
        }
        home_label
        home_link {
          ...Link
        }
      }
    }
  }
`;

type Props = {
  primary: {
    next_label: RichTextBlock[];
    next_link: PrismicLink;
    previous_label: RichTextBlock[];
    previous_link: PrismicLink;
    home_label: RichTextBlock[];
    home_link: PrismicLink;
  };
};

export const parse = (slice: Props, redirects): IPageShortcuts => ({
  type: SliceTypes.PageShortcuts,
  nextLabel: parser.string(slice.primary.next_label, redirects),
  nextLink: parser.link(slice.primary.next_link, redirects),
  previousLabel: parser.string(slice.primary.previous_label, redirects),
  previousLink: parser.link(slice.primary.previous_link, redirects),
  homeLabel: parser.string(slice.primary.home_label, redirects),
  homeLink: parser.link(slice.primary.home_link, redirects),
});
