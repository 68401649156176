import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IImage, ILink } from 'utils/types';
import { IBaseSlice, SliceTypes } from '..';
import { PrismicImage, PrismicLink } from '../../utils/PrismicTypes';
import { parser } from '../../utils/parsers';

export interface IGalleryBlock extends IBaseSlice {
  title: string;
  text: RichTextBlock[];
  link: ILink;
  buttonText: string;
  buttonNumber: string;
  buttonLabel: string;
  buttonCaption: string;
  buttonSubline: string;
  items: Array<{
    image: IImage;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_title
        slice_text
        slice_link { ...Link}
        slice_buttontext
        slice_buttonnumber
        slice_buttonlabel
        slice_buttoncaption
        slice_buttonsubline
      }
      fields {
        item_image
      }
    }
  }
`;

type Props = {
  primary: {
    slice_title: RichTextBlock[];
    slice_text: RichTextBlock[];
    slice_link: PrismicLink;
    slice_buttontext: RichTextBlock[];
    slice_buttonnumber: RichTextBlock[];
    slice_buttonlabel: RichTextBlock[];
    slice_buttoncaption: RichTextBlock[];
    slice_buttonsubline: RichTextBlock[];
  };
  fields: Array<{
    item_image: PrismicImage;
  }>;
};

export const parse = (slice: Props, redirects): IGalleryBlock => ({
  type: SliceTypes.GalleryBlock,
  title: parser.string(slice.primary.slice_title),
  text: parser.richText(slice.primary.slice_text, redirects),
  link: parser.link(slice.primary.slice_link, redirects),
  buttonText: parser.string(slice.primary.slice_buttontext),
  buttonNumber: parser.string(slice.primary.slice_buttonnumber),
  buttonLabel: parser.string(slice.primary.slice_buttonlabel),
  buttonCaption: parser.string(slice.primary.slice_buttoncaption),
  buttonSubline: parser.string(slice.primary.slice_buttonsubline),
  items: slice.fields?.map((field) => ({
    image: parser.image(field.item_image),
  })),
});
